"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.designTokens = void 0;
exports.designTokens = {
    red: '#d0021b',
    inputPlaceHolderColor: 'rgb(220, 220, 220)',
    lightBlue: '#00aeef',
    lighterBlue: '#0491c6',
    menuBackgroundGrey: '#f5f5f5',
    menuBlue: '#0065A4',
    menuBlueSelected: '#28376A',
    menuGrey: '#808080',
    filterSettingsBackgroundBlue: '#DBE6F1',
};
