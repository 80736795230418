"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedDataTypes = void 0;
var React = require("react");
var react_1 = require("react");
var DataTypes_1 = require("../../../components/DataTypes");
var data_types_1 = require("../../../services/data-types");
var constants_1 = require("../../../../constants");
var authentication_1 = require("../../../../authentication");
var configuration_1 = require("../../../../configuration");
var cd_product_api_1 = require("../../../../../providers/cd-product-api");
var ConnectedDataTypes = function (_a) {
    var onChange = _a.onChange, slugs = _a.slugs, hasMultiSelect = _a.hasMultiSelect, shouldBeDisabled = _a.shouldBeDisabled;
    var baseServer = (0, configuration_1.useConfiguration)().baseServer;
    var accessToken = (0, authentication_1.useAuthentication)().accessToken;
    var cdProductAPIProvider = (0, react_1.useMemo)(function () {
        return (0, cd_product_api_1.createCDProductAPIProvider)({
            basePath: constants_1.DATA_TYPES_BASE_PATH,
            baseServer: baseServer,
            accessToken: accessToken,
        });
    }, [accessToken]);
    var dataTypesService = (0, react_1.useMemo)(function () {
        return (0, data_types_1.createDataTypesService)({
            cdProductAPIProvider: cdProductAPIProvider,
        });
    }, [cdProductAPIProvider]);
    return (React.createElement(DataTypes_1.DataTypes, { dataTypesService: dataTypesService, onChange: onChange, hasMultiSelect: hasMultiSelect, shouldBeDisabled: shouldBeDisabled, slugs: slugs }));
};
exports.ConnectedDataTypes = ConnectedDataTypes;
