"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureFlag = void 0;
var React = require("react");
var FeatureFlag = function (_a) {
    var children = _a.children, showFeature = _a.showFeature;
    if (!showFeature)
        return null;
    return React.createElement(React.Fragment, null, children);
};
exports.FeatureFlag = FeatureFlag;
