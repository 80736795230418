"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.openHelpPages = void 0;
var helpPagesUrl = "".concat(window.location.origin, "/help-pages.html?rca=true");
var openHelpPages = function () {
    window.Office.context.ui.displayDialogAsync(helpPagesUrl, {
        height: 75,
        width: 70,
    });
};
exports.openHelpPages = openHelpPages;
