"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_1 = require("react");
var styled_components_1 = require("styled-components");
var designTokens_1 = require("../../../../designTokens");
var TabContainer = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var Tab = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  cursor: ", ";\n  justify-content: center;\n  padding: 10px 0px;\n"], ["\n  display: flex;\n  cursor: ", ";\n  justify-content: center;\n  padding: 10px 0px;\n"])), function (_a) {
    var isDisabled = _a.isDisabled;
    if (isDisabled)
        return "not-allowed";
    return "pointer";
});
var TabSeparator = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 2px;\n  height: 50px;\n  margin: 0 12px;\n  background: #d0d0d0;\n"], ["\n  width: 2px;\n  height: 50px;\n  margin: 0 12px;\n  background: #d0d0d0;\n"])));
var TabButton = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  transition: all 0.33s;\n  border-bottom: 5px solid\n    ", ";\n  &:hover {\n    filter: brightness(85%);\n  }\n"], ["\n  transition: all 0.33s;\n  border-bottom: 5px solid\n    ", ";\n  &:hover {\n    filter: brightness(85%);\n  }\n"])), function (_a) {
    var isSelected = _a.isSelected;
    if (isSelected)
        return "#28376A";
    return "transparent";
});
var TabButtonImageContainer = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
var TabButtonLabel = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 16px;\n  color: ", ";\n"], ["\n  font-size: 16px;\n  color: ", ";\n"])), function (_a) {
    var isSelected = _a.isSelected, isDisabled = _a.isDisabled;
    return isSelected
        ? designTokens_1.designTokens.menuBlueSelected
        : isDisabled
            ? designTokens_1.designTokens.menuGrey
            : designTokens_1.designTokens.menuBlue;
});
var DEFAULT_ON_CLICK = function () { return ({}); };
var Tabs = function (_a) {
    var selected = _a.selected, options = _a.options, disabledTabs = _a.disabledTabs, _b = _a.onClick, onClick = _b === void 0 ? DEFAULT_ON_CLICK : _b;
    var createOnTabClick = function (option) { return function () {
        if (option.isDisabled) {
            return;
        }
        onClick(option);
    }; };
    return (React.createElement(TabContainer, null, options.map(function (option, i) {
        var label = option.label, _a = option.id, id = _a === void 0 ? label : _a, src = option.src, srcIsSelected = option.srcIsSelected, srcIsDisabled = option.srcIsDisabled;
        var isSelected = id === selected;
        var isDisabled = disabledTabs.includes(id);
        var shouldAddSeparator = i !== options.length - 1;
        return (React.createElement(react_1.Fragment, { key: i },
            React.createElement(Tab, { "data-testid": id, isSelected: isSelected, isDisabled: isDisabled, onClick: createOnTabClick(__assign(__assign({}, option), { id: id, isDisabled: isDisabled })) },
                React.createElement(TabButton, { "data-testid": "".concat(id, "-button"), isSelected: isSelected },
                    src && (React.createElement(TabButtonImageContainer, { isSelected: isSelected },
                        React.createElement("img", { src: isSelected
                                ? srcIsSelected
                                : isDisabled
                                    ? srcIsDisabled
                                    : src }))),
                    React.createElement(TabButtonLabel, { label: label, isSelected: isSelected, isDisabled: isDisabled }, label))),
            shouldAddSeparator && React.createElement(TabSeparator, null)));
    })));
};
exports.default = Tabs;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
