"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var styled_components_1 = require("styled-components");
var settings_1 = require("../../../../../features/settings/contexts/settings");
var PriceFloor_1 = require("../../../../../features/settings/components/Settings/PriceFloor");
var feature_flag_1 = require("../../../../../features/feature-flag/");
var help_pages_1 = require("./helpers/help-pages");
var InfoContainer = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var InfoItem = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 10px;\n"], ["\n  margin-bottom: 10px;\n"])));
var InfoText = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 13px;\n  color: #242424;\n"], ["\n  font-size: 13px;\n  color: #242424;\n"])));
var InfoItemViewDocuments = (0, styled_components_1.default)(InfoItem)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  > * + * {\n    margin-top: 10px;\n  }\n"], ["\n  > * + * {\n    margin-top: 10px;\n  }\n"])));
var HelpPageLink = styled_components_1.default.a(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: block;\n  color: rgb(85, 26, 139);\n  cursor: pointer;\n"], ["\n  display: block;\n  color: rgb(85, 26, 139);\n  cursor: pointer;\n"])));
var Info = function () {
    var settings = (0, settings_1.useSettings)().settings;
    var handleHelpPageLinkClick = function (event) {
        event.preventDefault();
        (0, help_pages_1.openHelpPages)();
    };
    return (React.createElement(InfoContainer, null,
        React.createElement(InfoItem, null,
            React.createElement(InfoText, null,
                "Display Currency as ",
                settings.currency,
                "."),
            React.createElement(InfoText, null,
                "Display Rentable Area as ",
                settings.rentableArea,
                "."),
            React.createElement(InfoText, null,
                PriceFloor_1.priceFloorLabelMap[settings.priceFloor],
                " Price Floor applied.")),
        React.createElement(InfoItemViewDocuments, null,
            React.createElement(InfoText, null, "Data is refreshed daily"),
            React.createElement(HelpPageLink, { role: 'button', onClick: handleHelpPageLinkClick }, "View supporting documents"),
            React.createElement(feature_flag_1.FeatureFlag, { flag: "ff-debug-test-false" },
                React.createElement("div", { "data-testid": "ff-debug-test-false-hide-me" }, "~")))));
};
exports.default = Info;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
