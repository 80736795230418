"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedMainProvider = void 0;
var React = require("react");
var ConfigurationProvider_1 = require("../ConfigurationProvider");
var AuthenticationProvider_1 = require("../AuthenticationProvider");
var SearchOptionsProvider_1 = require("../SearchOptionsProvider");
var top_level_state_1 = require("../../../top-level-state");
var ConnectedMainProvider = function (_a) {
    var children = _a.children;
    return (React.createElement(top_level_state_1.TopLevelStateProvider, null,
        React.createElement(ConfigurationProvider_1.ConnectedConfigurationProvider, null,
            React.createElement(AuthenticationProvider_1.ConnectedAuthenticationProvider, null,
                React.createElement(SearchOptionsProvider_1.ConnectedSearchOptionsProvider, null, children)))));
};
exports.ConnectedMainProvider = ConnectedMainProvider;
