"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrimaryButton = void 0;
var styled_components_1 = require("styled-components");
var designTokens_1 = require("../../designTokens");
exports.PrimaryButton = styled_components_1.default.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: center;\n  font-family: Roboto !important;\n  font-size: 18px;\n  -webkit-font-smoothing: antialiased;\n  background-color: ", ";\n  color: white;\n  width: 100%;\n  outline: none;\n  text-decoration: none;\n  border-radius: 3px;\n  border-style: solid;\n  border-color: #00aeef;\n  padding: 13px 22px;\n  width: 100%;\n  :hover {\n    cursor: pointer;\n    background-color: ", ";\n    border-color: ", ";\n  }\n"], ["\n  text-align: center;\n  font-family: Roboto !important;\n  font-size: 18px;\n  -webkit-font-smoothing: antialiased;\n  background-color: ", ";\n  color: white;\n  width: 100%;\n  outline: none;\n  text-decoration: none;\n  border-radius: 3px;\n  border-style: solid;\n  border-color: #00aeef;\n  padding: 13px 22px;\n  width: 100%;\n  :hover {\n    cursor: pointer;\n    background-color: ", ";\n    border-color: ", ";\n  }\n"])), designTokens_1.designTokens.lightBlue, designTokens_1.designTokens.lighterBlue, designTokens_1.designTokens.lighterBlue);
var templateObject_1;
