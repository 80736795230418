"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DynamicTextInput = exports.Label = void 0;
var styled_components_1 = require("styled-components");
var designTokens_1 = require("../../designTokens");
exports.Label = styled_components_1.default.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 17px;\n  color: #707070;\n  font-family: Roboto;\n  margin-top: 3px;\n"], ["\n  font-size: 17px;\n  color: #707070;\n  font-family: Roboto;\n  margin-top: 3px;\n"])));
var inputBaseCSS = (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  margin-top: 10px;\n  font-family: Roboto;\n  font-size: 14px;\n  line-height: 21px;\n  -webkit-font-smoothing: antialiased;\n  text-transform: none;\n  border-width: 1px;\n  border-style: solid;\n  border-image: initial;\n  padding: 15px 15px;\n  outline: none;\n  box-sizing: border-box;\n  width: 100%;\n"], ["\n  position: relative;\n  margin-top: 10px;\n  font-family: Roboto;\n  font-size: 14px;\n  line-height: 21px;\n  -webkit-font-smoothing: antialiased;\n  text-transform: none;\n  border-width: 1px;\n  border-style: solid;\n  border-image: initial;\n  padding: 15px 15px;\n  outline: none;\n  box-sizing: border-box;\n  width: 100%;\n"])));
exports.DynamicTextInput = styled_components_1.default.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  border-color: ", ";\n  ", " ::placeholder,\n  :focus {\n    outline: none;\n    border: 1px solid #00aeef;\n  }\n"], ["\n  color: ", ";\n  border-color: ", ";\n  ", " ::placeholder,\n  :focus {\n    outline: none;\n    border: 1px solid #00aeef;\n  }\n"])), function (props) { return (props.error ? designTokens_1.designTokens.red : 'black'); }, function (props) { return (props.error ? designTokens_1.designTokens.red : 'gainsboro'); }, inputBaseCSS);
var templateObject_1, templateObject_2, templateObject_3;
