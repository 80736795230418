"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTransactionsSearch = void 0;
var react_1 = require("react");
var hooks_1 = require("../../../spreadsheet/hooks");
var contexts_1 = require("../../contexts");
var global_errors_1 = require("../../../../features/global-errors/contexts/global-errors");
var DEFAULT_STATE = {
    trendsData: null,
    trendsDto: null,
    isLoading: false,
    isRefreshing: false,
    error: null,
};
function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}
var reducer = function (state, action) {
    var type = action.type;
    switch (type) {
        case "LOAD_TRENDS": {
            return __assign(__assign({}, state), { isLoading: true, trendsDto: action.trendsDto });
        }
        case "LOAD_TRENDS_SUCCESS": {
            return __assign(__assign({}, state), { isLoading: false, error: null, trendsData: action.trendsData });
        }
        case "LOAD_TRENDS_ERROR": {
            return __assign(__assign({}, state), { trendsData: null, isLoading: false, error: action.error });
        }
        case "REFRESH_TRENDS": {
            return __assign(__assign({}, state), { isLoading: true, isRefreshing: true });
        }
        case "REFRESH_TRENDS_SUCCESS": {
            return __assign(__assign({}, state), { isRefreshing: false, isLoading: false, error: null });
        }
        case "REFRESH_TRENDS_ERROR": {
            return __assign(__assign({}, state), { trendsData: null, isRefreshing: false, isLoading: false, error: action.error });
        }
        /* istanbul ignore next */
        default: {
            return state;
        }
    }
};
var useTransactionsSearch = function () {
    var setGlobalError = (0, global_errors_1.useGlobalErrors)().setGlobalError;
    var _a = (0, hooks_1.useSpreadsheet)(), writeTrends = _a.writeTrends, getTableIdsSearchIdsMap = _a.getTableIdsSearchIdsMap, writeRefreshTrends = _a.writeRefreshTrends;
    var _b = (0, contexts_1.useTransactionSearchDeps)(), transactionsSearchService = _b.transactionsSearchService, spreadsheetService = _b.spreadsheetService;
    var _c = (0, react_1.useReducer)(reducer, DEFAULT_STATE), state = _c[0], dispatch = _c[1];
    var loadTrends = (0, react_1.useCallback)(function (trendsDto) { return dispatch({ type: "LOAD_TRENDS", trendsDto: trendsDto }); }, []);
    var loadTrendsSuccess = (0, react_1.useCallback)(function (_a) {
        var trendsData = _a.trendsData;
        return dispatch({ type: "LOAD_TRENDS_SUCCESS", trendsData: trendsData });
    }, []);
    var loadTrendsError = (0, react_1.useCallback)(function (_a) {
        var error = _a.error;
        return dispatch({ type: "LOAD_TRENDS_ERROR", error: error });
    }, []);
    var refreshTrends = (0, react_1.useCallback)(function () { return dispatch({ type: "REFRESH_TRENDS" }); }, []);
    var refreshTrendsSuccess = (0, react_1.useCallback)(function () { return dispatch({ type: "REFRESH_TRENDS_SUCCESS" }); }, []);
    var refreshTrendsError = (0, react_1.useCallback)(function (_a) {
        var error = _a.error;
        return dispatch({ type: "REFRESH_TRENDS_ERROR", error: error });
    }, []);
    var load = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var canGetActiveCell, trendsDto, trends, _a, message;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, spreadsheetService.canGetActiveCell()];
                case 1:
                    canGetActiveCell = _b.sent();
                    if (!canGetActiveCell) {
                        loadTrendsError({
                            error: global_errors_1.cellEditingErrorMessage,
                        });
                        return [2 /*return*/];
                    }
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 5, , 6]);
                    trendsDto = state.trendsDto;
                    return [4 /*yield*/, transactionsSearchService.fetchTrends(trendsDto)];
                case 3:
                    trends = _b.sent();
                    return [4 /*yield*/, writeTrends({ trends: trends })];
                case 4:
                    _b.sent();
                    loadTrendsSuccess(trends);
                    return [3 /*break*/, 6];
                case 5:
                    _a = _b.sent();
                    message = _a.message;
                    loadTrendsError({ error: message });
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [
        state.trendsDto,
        transactionsSearchService,
        writeTrends,
        loadTrendsSuccess,
        loadTrendsError,
    ]);
    var refresh = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var canGetActiveCell, tableIdsSearchIdsMap, _a, message, tableIds, refresh_1, _b, message;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, spreadsheetService.canGetActiveCell()];
                case 1:
                    canGetActiveCell = _c.sent();
                    if (!canGetActiveCell) {
                        refreshTrendsError({
                            error: global_errors_1.cellEditingErrorMessage,
                        });
                        return [2 /*return*/];
                    }
                    _c.label = 2;
                case 2:
                    _c.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, getTableIdsSearchIdsMap()];
                case 3:
                    (tableIdsSearchIdsMap = (_c.sent()).tableIdsSearchIdsMap);
                    if (isEmpty(tableIdsSearchIdsMap)) {
                        throw new Error(global_errors_1.refreshErrorMessage);
                    }
                    return [3 /*break*/, 5];
                case 4:
                    _a = _c.sent();
                    message = _a.message;
                    refreshTrendsError({ error: message });
                    return [2 /*return*/];
                case 5:
                    tableIds = Object.keys(tableIdsSearchIdsMap);
                    _c.label = 6;
                case 6:
                    _c.trys.push([6, 9, , 10]);
                    refresh_1 = tableIds.map(function (tableId) { return __awaiter(void 0, void 0, void 0, function () {
                        var searchId, trends;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    searchId = tableIdsSearchIdsMap[tableId];
                                    return [4 /*yield*/, transactionsSearchService.fetchTrendsById([
                                            searchId,
                                        ])];
                                case 1:
                                    trends = _a.sent();
                                    return [4 /*yield*/, writeRefreshTrends({ trends: trends, tableId: tableId })];
                                case 2:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                    return [4 /*yield*/, Promise.all(refresh_1)];
                case 7:
                    _c.sent();
                    return [4 /*yield*/, refreshTrendsSuccess()];
                case 8:
                    _c.sent();
                    return [3 /*break*/, 10];
                case 9:
                    _b = _c.sent();
                    message = _b.message;
                    refreshTrendsError({ error: message });
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }); }, [transactionsSearchService]);
    (0, react_1.useEffect)(function () {
        if (state.isLoading && !state.isRefreshing)
            load();
    }, [state.isLoading]);
    (0, react_1.useEffect)(function () {
        if (state.isRefreshing) {
            refresh();
        }
    }, [state.isRefreshing]);
    (0, react_1.useEffect)(function () {
        setGlobalError({ error: state.error });
    }, [state.error]);
    return __assign(__assign({}, state), { loadTrends: loadTrends, refreshTrends: refreshTrends });
};
exports.useTransactionsSearch = useTransactionsSearch;
