"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHeaderRange = exports.getTableRange = exports.getColumnRange = void 0;
//https://stackoverflow.com/a/46175899
var nextChar = function (c) { return (c ? String.fromCharCode(c.charCodeAt(0) + 1) : 'A'); };
var nextCol = function (s) {
    return s.replace(/([^Z]?)(Z*)$/, function (_, a, z) { return nextChar(a) + z.replace(/Z/g, 'A'); });
};
var getColumnRange = function (_a) {
    var length = _a.length, activeColumn = _a.activeColumn, activeRow = _a.activeRow;
    var nextColumn = activeColumn;
    return Array(length + 1)
        .fill('')
        .reduce(function (acc, item, index) {
        if (index === 0) {
            acc = "".concat(activeColumn).concat(activeRow);
            return acc;
        }
        nextColumn = nextCol(nextColumn);
        acc = acc + ":".concat(nextColumn).concat(activeRow);
        return acc;
    }, 0);
};
exports.getColumnRange = getColumnRange;
var getTableRange = function (_a) {
    var columnLength = _a.columnLength, rowLength = _a.rowLength, firstColumn = _a.firstColumn, firstRow = _a.firstRow;
    var nextColumn = firstColumn;
    if (columnLength === 1) {
        return "".concat(firstColumn).concat(firstRow, ":").concat(firstColumn).concat(firstRow + rowLength - 1);
    }
    return Array(columnLength - 1)
        .fill('')
        .reduce(function (acc) {
        nextColumn = nextCol(nextColumn);
        acc =
            "".concat(firstColumn).concat(firstRow) +
                ":".concat(nextColumn).concat(firstRow + rowLength - 1);
        return acc;
    }, 0);
};
exports.getTableRange = getTableRange;
var getHeaderRange = function (_a) {
    var activeColumn = _a.activeColumn, activeRow = _a.activeRow, rangeRows = _a.rangeRows;
    var nextColumn = nextCol(nextCol(activeColumn));
    return "".concat(activeColumn).concat(activeRow, ":").concat(nextColumn).concat(activeRow + rangeRows - 1);
};
exports.getHeaderRange = getHeaderRange;
