"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNumberFormat = exports.numberFormats = void 0;
exports.numberFormats = {
    percentage: '0.00%',
    default: "#,##0.00",
};
var getNumberFormat = function (dataType) {
    switch (dataType) {
        case 'cap-rate':
        case 'top-quartile-cap-rate':
        case 'year-over-year':
            return exports.numberFormats.percentage;
        default:
            return exports.numberFormats.default;
    }
};
exports.getNumberFormat = getNumberFormat;
