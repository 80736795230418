"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormikCheckbox = exports.Input = exports.Label = void 0;
var formik_1 = require("formik");
var React = require("react");
var styled_components_1 = require("styled-components");
exports.Label = styled_components_1.default.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 17px;\n  -webkit-font-smoothing: antialiased;\n  color: #707070;\n  margin-left: 3px;\n  line-height: 20px;\n  font-family: Roboto;\n"], ["\n  font-size: 17px;\n  -webkit-font-smoothing: antialiased;\n  color: #707070;\n  margin-left: 3px;\n  line-height: 20px;\n  font-family: Roboto;\n"])));
exports.Input = styled_components_1.default.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: 1px;\n"], ["\n  margin-left: 1px;\n"])));
var FormikCheckbox = function (_a) {
    var name = _a.name, label = _a.label, dataTestId = _a.dataTestId;
    var field = (0, formik_1.useField)({ name: name })[0];
    return (React.createElement("div", { style: { display: 'flex' } },
        React.createElement(exports.Input, __assign({ "data-testid": dataTestId, id: name, checked: field.value, type: "checkbox" }, field)),
        React.createElement(exports.Label, { htmlFor: name }, label)));
};
exports.FormikCheckbox = FormikCheckbox;
var templateObject_1, templateObject_2;
