"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSpreadsheetService = void 0;
var map_headers_1 = require("../../helpers/map-headers");
var table_range_1 = require("../../helpers/table-range");
var table_properties_1 = require("../../helpers/table-properties");
var number_format_1 = require("../../helpers/number-format");
var spaceBetweenHeaderAndTable = 3;
var adaptSeriesForDifferentStartDates = function (_a) {
    var series = _a.series;
    if (series.length > 1) {
        var longestSeries = series.reduce(function (acc, value) {
            return value.trends.length > acc.trends.length ? value : acc;
        });
        var longestSeriesLength_1 = longestSeries.trends.length;
        var adaptedSeries = series.map(function (singleSeries) {
            var arrayFill = new Array(longestSeriesLength_1 - singleSeries.trends.length).fill(false);
            return __assign(__assign({}, singleSeries), { trends: arrayFill.concat(singleSeries.trends) });
        });
        return { adaptedSeries: adaptedSeries, longestSeries: longestSeries };
    }
    return { adaptedSeries: series, longestSeries: series[0] };
};
var createLabelHeaderData = function (_a) {
    var series = _a.series;
    var geographyFilterLabels = __spreadArray([
        ''
    ], series.map(function (singleSeries) { return singleSeries.label.geographyFilterLabel; }), true);
    var propertyTypeLabels = __spreadArray([
        ''
    ], series.map(function (singleSeries) { return singleSeries.label.propertyTypeLabel; }), true);
    var dataTypeLabels = __spreadArray([
        'Date'
    ], series.map(function (singleSeries) { return singleSeries.label.dataTypeLabel; }), true);
    return [geographyFilterLabels, propertyTypeLabels, dataTypeLabels];
};
var createSeriesHeadersFormatsAndData = function (_a) {
    var series = _a.series;
    var _b = adaptSeriesForDifferentStartDates({
        series: series,
    }), adaptedSeries = _b.adaptedSeries, longestSeries = _b.longestSeries;
    var numberFormats = longestSeries.trends.map(function () { return __spreadArray([
        null
    ], adaptedSeries.map(function (_a) {
        var dataType = _a.dataType;
        return (0, number_format_1.getNumberFormat)(dataType);
    }), true); });
    var data = longestSeries.trends.map(function (item, index) {
        var row = __spreadArray([
            item.date
        ], adaptedSeries.map(function (item) {
            return item.trends[index] ? item.trends[index].value : '';
        }), true);
        return row;
    });
    return { numberFormats: numberFormats, data: data };
};
var spreadsheetService = function (_a) {
    var excelProvider = _a.excelProvider;
    return ({
        writeTrendsReports: function (_a) {
            var _b = _a.trends, series = _b.series, headers = _b.headers, searchId = _b.searchId;
            return __awaiter(void 0, void 0, void 0, function () {
                var _c, activeColumn, activeRow, headersData, headerHeight, headerTableConfig, range, _d, data, numberFormats, trendsTableConfigs, tableId, headerAboveTableColumnRange, customProperty;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0: return [4 /*yield*/, excelProvider.getActiveColumnAndRowValue()];
                        case 1:
                            _c = _e.sent(), activeColumn = _c.activeColumn, activeRow = _c.activeRow;
                            headersData = (0, map_headers_1.mapHeaders)({ headers: headers }).tableData;
                            headerHeight = headersData.length;
                            headerTableConfig = {
                                name: 'TrendsHeaderTable',
                                range: (0, table_range_1.getHeaderRange)({
                                    activeColumn: activeColumn,
                                    activeRow: activeRow,
                                    rangeRows: headerHeight,
                                }),
                                data: headersData,
                            };
                            return [4 /*yield*/, excelProvider.createHeader(__assign({}, headerTableConfig))];
                        case 2:
                            _e.sent();
                            range = (0, table_range_1.getColumnRange)({
                                length: series.length,
                                activeColumn: activeColumn,
                                activeRow: activeRow + headerHeight + spaceBetweenHeaderAndTable - 1,
                            });
                            _d = createSeriesHeadersFormatsAndData({
                                series: series,
                            }), data = _d.data, numberFormats = _d.numberFormats;
                            trendsTableConfigs = {
                                name: 'TrendsDataTable',
                                range: range,
                                data: data,
                                numberFormats: numberFormats,
                            };
                            return [4 /*yield*/, excelProvider.createTable({
                                    tableConfig: __assign({}, trendsTableConfigs),
                                })];
                        case 3:
                            tableId = _e.sent();
                            headerAboveTableColumnRange = (0, table_range_1.getTableRange)({
                                columnLength: series.length + 1,
                                rowLength: 3,
                                firstColumn: activeColumn,
                                firstRow: activeRow + headerHeight,
                            });
                            return [4 /*yield*/, excelProvider.createHeader({
                                    data: createLabelHeaderData({ series: series }),
                                    range: headerAboveTableColumnRange,
                                })];
                        case 4:
                            _e.sent();
                            return [4 /*yield*/, excelProvider.createCustomProperty({
                                    key: "".concat(tableId, "_searchId"),
                                    value: searchId,
                                })];
                        case 5:
                            customProperty = _e.sent();
                            return [2 /*return*/, { customProperty: customProperty }];
                    }
                });
            });
        },
        canGetActiveCell: function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, excelProvider.getActiveColumnAndRowValue()];
                    case 1:
                        _b.sent();
                        return [2 /*return*/, true];
                    case 2:
                        _a = _b.sent();
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        }); },
        refreshTrendsReports: function (_a) {
            var _b = _a.trends, series = _b.series, headers = _b.headers, tableId = _a.tableId;
            return __awaiter(void 0, void 0, void 0, function () {
                var excelTableId, data, _c, firstRow, firstColumn, headersData, headerHeight;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            excelTableId = tableId.replace('_searchId', '');
                            data = createSeriesHeadersFormatsAndData({ series: series }).data;
                            return [4 /*yield*/, excelProvider.getTableFirstColumnAndRowValue({ excelTableId: excelTableId })];
                        case 1:
                            _c = _d.sent(), firstRow = _c.firstRow, firstColumn = _c.firstColumn;
                            return [4 /*yield*/, excelProvider.updateTableDataBodyRange({
                                    data: data,
                                    firstRow: firstRow,
                                    firstColumn: firstColumn,
                                })];
                        case 2:
                            _d.sent();
                            headersData = (0, map_headers_1.mapHeaders)({ headers: headers }).tableData;
                            headerHeight = headersData.length;
                            return [4 /*yield*/, excelProvider.updateHeaderTableBodyRange({
                                    headersData: headersData,
                                    firstRow: firstRow - headerHeight - spaceBetweenHeaderAndTable,
                                    firstColumn: firstColumn,
                                })];
                        case 3:
                            _d.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        getDocumentPersistedTableIdsSearchIds: function () { return __awaiter(void 0, void 0, void 0, function () {
            var tableIds, tableIdsSearchIdsMap;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, excelProvider.getTableIdsInActiveWorksheet()];
                    case 1:
                        tableIds = _a.sent();
                        return [4 /*yield*/, (0, table_properties_1.createTableIdsSearchIdsMap)({
                                tableIds: tableIds,
                                searchIdGetter: function (tableId) {
                                    return excelProvider.getCustomPropertyValue({
                                        key: "".concat(tableId, "_searchId"),
                                    });
                                },
                            })];
                    case 2:
                        tableIdsSearchIdsMap = (_a.sent()).tableIdsSearchIdsMap;
                        return [2 /*return*/, { tableIdsSearchIdsMap: tableIdsSearchIdsMap }];
                }
            });
        }); },
    });
};
var createSpreadsheetService = function (_a) {
    var excelProvider = _a.excelProvider;
    return spreadsheetService({
        excelProvider: excelProvider,
    });
};
exports.createSpreadsheetService = createSpreadsheetService;
