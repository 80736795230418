"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Settings = void 0;
var React = require("react");
var react_1 = require("react");
var styled_components_1 = require("styled-components");
var Currency_1 = require("./Currency");
var RentableArea_1 = require("./RentableArea");
var PriceFloor_1 = require("./PriceFloor");
var settings_1 = require("../../contexts/settings");
var designTokens_1 = require("../../../../../src/taskpane/designTokens");
var Container = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #f9f9f9;\n  font-weight: bold;\n  font-family: Arial;\n  color: #505050;\n"], ["\n  background-color: #f9f9f9;\n  font-weight: bold;\n  font-family: Arial;\n  color: #505050;\n"])));
var HeaderContainer = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: 7px;\n  display: flex;\n  justify-content: space-between;\n  font-size: 16px;\n  cursor: pointer;\n"], ["\n  background-color: ", ";\n  padding: 7px;\n  display: flex;\n  justify-content: space-between;\n  font-size: 16px;\n  cursor: pointer;\n"])), designTokens_1.designTokens.filterSettingsBackgroundBlue);
var TitleContainer = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var SettingsIconContainer = styled_components_1.default.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 0 5px 0 5px;\n"], ["\n  padding: 0 5px 0 5px;\n"])));
var ExpandIconContainer = styled_components_1.default.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding-right: 5px;\n"], ["\n  padding-right: 5px;\n"])));
var BodyContainer = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-left: 8px;\n  margin-right: 8px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-left: 8px;\n  margin-right: 8px;\n"])));
var SaveSettingsButton = styled_components_1.default.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border: none;\n  cursor: ", ";\n  font-family: Roboto !important;\n  background: ", ";\n  width: 100%;\n  font-size: 18px;\n  font-weight: 1000;\n  color: white;\n  text-align: center;\n  padding: 10px;\n  margin-bottom: 10px;\n  opacity: ", ";\n  &:hover {\n    ", ";\n  }\n"], ["\n  border: none;\n  cursor: ", ";\n  font-family: Roboto !important;\n  background: ", ";\n  width: 100%;\n  font-size: 18px;\n  font-weight: 1000;\n  color: white;\n  text-align: center;\n  padding: 10px;\n  margin-bottom: 10px;\n  opacity: ", ";\n  &:hover {\n    ", ";\n  }\n"])), function (_a) {
    var hasBeenTouched = _a.hasBeenTouched;
    return (hasBeenTouched ? 'pointer' : 'auto');
}, designTokens_1.designTokens.menuBlueSelected, function (_a) {
    var hasBeenTouched = _a.hasBeenTouched;
    return (hasBeenTouched ? 1 : 0.5);
}, function (_a) {
    var hasBeenTouched = _a.hasBeenTouched;
    return hasBeenTouched && 'filter: brightness(115%)';
});
var SettingsDropdownsContainer = styled_components_1.default.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding-bottom: 20px;\n  font-size: 14px;\n  width: 100%;\n"], ["\n  padding-bottom: 20px;\n  font-size: 14px;\n  width: 100%;\n"])));
var Settings = function () {
    var _a = (0, settings_1.useSettings)(), settings = _a.settings, save = _a.save;
    var _b = (0, react_1.useState)(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = (0, react_1.useState)(settings), selectedSettings = _c[0], setSelectedSettings = _c[1];
    var _d = (0, react_1.useState)(false), hasBeenTouched = _d[0], setHasBeenTouched = _d[1];
    var selectedCurrency = selectedSettings.currency, selectedRentableArea = selectedSettings.rentableArea, selectedPriceFloor = selectedSettings.priceFloor;
    (0, react_1.useEffect)(function () {
        setSelectedSettings(settings);
    }, [settings]);
    (0, react_1.useEffect)(function () {
        if (!isOpen) {
            setSelectedSettings(settings);
        }
    }, [isOpen]);
    var onSaveButtonClick = function () {
        if (hasBeenTouched) {
            save({
                settings: {
                    currency: selectedCurrency,
                    rentableArea: selectedRentableArea,
                    priceFloor: selectedPriceFloor,
                },
            });
            setHasBeenTouched(false);
            setIsOpen(!isOpen);
        }
    };
    var onCurrencyChange = function (currency) {
        if (selectedSettings.currency !== currency) {
            setHasBeenTouched(true);
        }
        setSelectedSettings(__assign(__assign({}, selectedSettings), { currency: currency }));
    };
    var onRentableAreaChange = function (rentableArea) {
        if (selectedSettings.rentableArea !== rentableArea) {
            setHasBeenTouched(true);
        }
        setSelectedSettings(__assign(__assign({}, selectedSettings), { rentableArea: rentableArea }));
    };
    var onPriceFloorChange = function (priceFloor) {
        if (selectedSettings.priceFloor !== priceFloor) {
            setHasBeenTouched(true);
        }
        setSelectedSettings(__assign(__assign({}, selectedSettings), { priceFloor: priceFloor }));
    };
    var onHeaderClick = function () {
        setIsOpen(!isOpen);
    };
    return (React.createElement(Container, null,
        React.createElement(HeaderContainer, { "data-testid": 'settings-header-container', onClick: onHeaderClick },
            React.createElement(TitleContainer, null,
                React.createElement(SettingsIconContainer, { src: "assets/gear-fill.svg", alt: "filter settings icon" }),
                React.createElement("span", null, "Filter Settings")),
            isOpen && (React.createElement(ExpandIconContainer, { src: "assets/chevron-up.svg", alt: "expand settings" })),
            !isOpen && (React.createElement(ExpandIconContainer, { src: "assets/chevron-down.svg", alt: "expand settings" }))),
        isOpen && (React.createElement(BodyContainer, null,
            React.createElement(SettingsDropdownsContainer, null,
                React.createElement(Currency_1.CurrencySettings, { selectedCurrency: selectedCurrency, onCurrencyChange: onCurrencyChange }),
                React.createElement(RentableArea_1.RentableAreaSettings, { selectedRentableArea: selectedRentableArea, onRentableAreaChange: onRentableAreaChange }),
                React.createElement(PriceFloor_1.PriceFloorSettings, { selectedPriceFloor: selectedPriceFloor, onPriceFloorChange: onPriceFloorChange })),
            React.createElement(SaveSettingsButton, { hasBeenTouched: hasBeenTouched, onClick: onSaveButtonClick }, "Save Settings")))));
};
exports.Settings = Settings;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
