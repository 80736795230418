"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceFloorSettings = exports.priceFloorLabelMap = void 0;
var React = require("react");
var SettingDropdown_1 = require("../SettingDropdown");
var price_floor_1 = require("../../../types/price-floor");
exports.priceFloorLabelMap = {
    US_2_5_GLOBAL_10: '$2.5M for US Markets and $10M for non-US Markets',
    US_5_GLOBAL_10: '$5M for US Markets and $10M for non-US Markets',
    ALL_10: '$10M For All Markets',
    ALL_25: '$25M For All Markets',
    ALL_50: '$50M For All Markets',
    ALL_100: '$100M For All Markets',
};
var priceFloorOptions = price_floor_1.priceFloors.map(function (priceFloor) { return ({
    priceFloor: priceFloor,
    label: exports.priceFloorLabelMap[priceFloor],
}); });
var PriceFloorSettings = function (_a) {
    var selectedPriceFloor = _a.selectedPriceFloor, onPriceFloorChange = _a.onPriceFloorChange;
    var onChange = function (option) {
        return onPriceFloorChange(option.priceFloor);
    };
    return (React.createElement(SettingDropdown_1.SettingDropdown, { label: "Price Floor", placeholder: exports.priceFloorLabelMap[selectedPriceFloor], valuePropertyName: "priceFloor", options: priceFloorOptions, onChange: onChange }));
};
exports.PriceFloorSettings = PriceFloorSettings;
