"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalFormError = void 0;
var Separator_1 = require("../Separator");
var Messages_1 = require("../Messages");
var React = require("react");
var GlobalFormError = function (_a) {
    var globalError = _a.globalError;
    if (globalError === 'SUPPRESSED' || !globalError) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Separator_1.Spacing, { height: "5px" }),
        React.createElement(Messages_1.FormErrorMessage, { textAlign: "center", color: "red", fontSize: "13px", mt: "7px" }, globalError)));
};
exports.GlobalFormError = GlobalFormError;
