"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChildOptionContainer = void 0;
var geographies_state_1 = require("../../contexts/geographies-state");
var ChildOptionContainer = function (_a) {
    var id = _a.id, children = _a.children;
    var isExpanded = (0, geographies_state_1.useGeographiesState)().expander.isExpanded;
    if (!isExpanded({ id: id }))
        return null;
    return children;
};
exports.ChildOptionContainer = ChildOptionContainer;
