"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.App = void 0;
var React = require("react");
require("@rcanalytics/shared-ui-components/dist/styles.css");
var AuthenticatedApp_1 = require("./apps/AuthenticatedApp");
var UnAuthenticatedApp_1 = require("./apps/UnAuthenticatedApp");
var authentication_1 = require("../features/authentication");
var SettingsProvider_1 = require("../features/main-provider/connected/SettingsProvider");
var App = function () {
    var isLoggedIn = (0, authentication_1.useAuthentication)().isLoggedIn;
    return isLoggedIn ? (React.createElement(SettingsProvider_1.ConnectedSettingsProvider, null,
        React.createElement(AuthenticatedApp_1.AuthenticatedApp, null))) : (React.createElement(UnAuthenticatedApp_1.UnAuthenticatedApp, null));
};
exports.App = App;
