"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LandingPage = void 0;
var React = require("react");
var react_1 = require("react");
var styled_components_1 = require("styled-components");
require("@rcanalytics/shared-ui-components/dist/styles.css");
var Headings_1 = require("../../../../components/Headings");
var components_1 = require("../../../../components");
var Auth0Authentication_1 = require("../../../../components/Auth0Authentication/Auth0Authentication");
var LandingPage = function () {
    var _a = (0, react_1.useState)(false), showAuth0Login = _a[0], setShowAuth0Login = _a[1];
    var handleClick = function () {
        setShowAuth0Login(true);
    };
    var handleLoginDialogClose = function () {
        setShowAuth0Login(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(Headings_1.Header, null),
            React.createElement(Main, null,
                React.createElement(components_1.Logo, null),
                React.createElement(Pitch, null, "Harness RCA\u2019s cutting-edge analytics within Excel!"),
                React.createElement(Import, null, "Instantly import RCA\u2019s Data into Excel"),
                React.createElement(components_1.Spacing, { height: '50px' }),
                React.createElement(components_1.PrimaryButton, { "data-testid": 'landing-page-login-button', onClick: handleClick }, 'Log In'),
                showAuth0Login &&
                    React.createElement(Auth0Authentication_1.default, { handleLoginClose: handleLoginDialogClose })),
            React.createElement(components_1.Footer, null))));
};
exports.LandingPage = LandingPage;
var Container = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0px 20px;\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: auto 1fr auto;\n  grid-template-areas:\n    'header'\n    'main'\n    'footer';\n"], ["\n  margin: 0px 20px;\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: auto 1fr auto;\n  grid-template-areas:\n    'header'\n    'main'\n    'footer';\n"])));
var Main = styled_components_1.default.main(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-area: main;\n  overflow: auto;\n"], ["\n  grid-area: main;\n  overflow: auto;\n"])));
var Pitch = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: center;\n  font: italic normal normal 24px Arial;\n  letter-spacing: 0px;\n  color: #000000;\n  opacity: 1;\n  margin: 46px 0px 0px 0px;\n"], ["\n  text-align: center;\n  font: italic normal normal 24px Arial;\n  letter-spacing: 0px;\n  color: #000000;\n  opacity: 1;\n  margin: 46px 0px 0px 0px;\n"])));
var ImportContainer = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  text-align: left;\n  font: normal normal normal 18px Arial;\n  letter-spacing: 0px;\n  color: #242424;\n  opacity: 1;\n  margin: 60px 0px 0px 0px;\n  display: grid;\n  grid-template-columns: auto 250px auto;\n"], ["\n  text-align: left;\n  font: normal normal normal 18px Arial;\n  letter-spacing: 0px;\n  color: #242424;\n  opacity: 1;\n  margin: 60px 0px 0px 0px;\n  display: grid;\n  grid-template-columns: auto 250px auto;\n"])));
var ImportContent = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  grid-column: 2;\n  display: grid;\n  grid-template-columns: auto 1fr;\n"], ["\n  grid-column: 2;\n  display: grid;\n  grid-template-columns: auto 1fr;\n"])));
var ImportIcon = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin: 0px 10px;\n  width: 38px;\n  height: 38px;\n"], ["\n  margin: 0px 10px;\n  width: 38px;\n  height: 38px;\n"])));
var Import = function (_a) {
    var children = _a.children;
    return (React.createElement(ImportContainer, null,
        React.createElement(ImportContent, null,
            React.createElement(ImportIcon, null,
                React.createElement("img", { src: "assets/import-icon-yellow.svg" })),
            React.createElement("div", null, children))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
