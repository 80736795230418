"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.currencies = void 0;
exports.currencies = [
    'USD',
    'EUR',
    'GBP',
    'JPY',
    'AUD',
    'CAN',
    'CNY',
    'LOC',
];
