"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapHeaders = void 0;
var price_floor_1 = require("../../settings/types/price-floor");
var rolling_average_types_1 = require("../../search-options/helpers/rolling-average-types");
var preliminaryMessage = function (reportRunDate) {
    var quarter = Math.floor((reportRunDate.getMonth() + 3) / 3);
    return "Q".concat(quarter, " ").concat(reportRunDate.getFullYear(), " Preliminary Data");
};
var mapHeaders = function (_a) {
    var headers = _a.headers;
    var reportRunDate = new Date(headers.reportRun);
    var message = headers.isPreliminaryData
        ? preliminaryMessage(reportRunDate)
        : '';
    var reportRow = [
        'Report Run:',
        "".concat(reportRunDate.getMonth() + 1, "/").concat(reportRunDate.getDate(), "/").concat(reportRunDate.getFullYear()),
        message,
    ];
    var tableData = [
        reportRow,
        ["Currency:", headers.currency, ''],
        ["Frequency:", headers.frequency, ''],
        ["Price Floor:", price_floor_1.priceFloorLabels[headers.priceFloor], ''],
        ["Rolling Average:", rolling_average_types_1.rollingAverageLabels[headers.rollingAverage], ''],
    ];
    return { tableData: tableData };
};
exports.mapHeaders = mapHeaders;
