"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAuthenticationLocalStorageService = void 0;
var jsonwebtoken_1 = require("jsonwebtoken");
var ACCESS_TOKEN = "accessToken";
var REFRESH_TOKEN = "refreshToken";
var ACCESS_TOKEN_EXPIRATION = "accessTokenExpiration";
var REFRESH_TOKEN_EXPIRATION = "refreshTokenExpiration";
var USERNAME = "username";
var isTokenExpired = function (_a) {
    var localStorage = _a.localStorage, tokenExpirationName = _a.tokenExpirationName;
    var expiration = localStorage.getItem(tokenExpirationName);
    if (expiration === null)
        return true;
    var currentTimeInSeconds = new Date().getTime() / 1000;
    return parseInt(expiration) < currentTimeInSeconds;
};
var authenticationLocalStorageService = function (_a) {
    var localStorage = _a.localStorage;
    return ({
        isAccessTokenExpired: function () {
            var accessToken = this.getAccessToken();
            var isAccessTokenExpired = !accessToken
                ? true
                : isTokenExpired({
                    localStorage: localStorage,
                    tokenExpirationName: ACCESS_TOKEN_EXPIRATION,
                });
            return isAccessTokenExpired;
        },
        isRefreshTokenExpired: function () {
            // const refreshToken = this.getRefreshToken();
            // const isRefreshTokenExpired = !refreshToken
            //   ? true
            //   : isTokenExpired({
            //       localStorage,
            //       tokenExpirationName: REFRESH_TOKEN_EXPIRATION,
            //     });
            // return isRefreshTokenExpired;
            return true;
        },
        areBothTokensExpired: function () {
            return this.isAccessTokenExpired() && this.isRefreshTokenExpired();
        },
        setLoginResults: function (_a) {
            var loginResults = _a.loginResults;
            var token = loginResults.token;
            var accessTokenExpiration = (0, jsonwebtoken_1.decode)(token).exp;
            // const refreshTokenExpiration =
            //   accessTokenExpiration + THIRTY_DAYS_IN_SECONDS;
            localStorage.setItem(ACCESS_TOKEN, token);
            // localStorage.setItem(REFRESH_TOKEN, refreshToken);
            localStorage.setItem(ACCESS_TOKEN_EXPIRATION, accessTokenExpiration);
            // localStorage.setItem(REFRESH_TOKEN_EXPIRATION, refreshTokenExpiration);
        },
        clearLoginResults: function () {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(REFRESH_TOKEN);
            localStorage.removeItem(ACCESS_TOKEN_EXPIRATION);
            localStorage.removeItem(REFRESH_TOKEN_EXPIRATION);
        },
        getUsernameFromAccessToken: function () {
            var accessToken = this.getAccessToken();
            if (!accessToken)
                return null;
            var username = (0, jsonwebtoken_1.decode)(accessToken).email;
            return username;
        },
        getAccessToken: function () {
            return localStorage.getItem(ACCESS_TOKEN);
        },
        getRefreshToken: function () {
            return localStorage.getItem(REFRESH_TOKEN);
        },
        getUsername: function () {
            return localStorage.getItem(USERNAME);
        },
        setUsername: function (_a) {
            var username = _a.username;
            localStorage.setItem(USERNAME, username);
        },
        removeUsername: function () {
            localStorage.removeItem(USERNAME);
        },
    });
};
var createAuthenticationLocalStorageService = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.localStorage, localStorage = _c === void 0 ? window.localStorage : _c;
    return authenticationLocalStorageService({ localStorage: localStorage });
};
exports.createAuthenticationLocalStorageService = createAuthenticationLocalStorageService;
