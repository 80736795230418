"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGlobalErrors = exports.GlobalErrorsProvider = exports.isValidErrorMessage = exports.errorMessage = exports.refreshErrorMessage = exports.internetConnectionMessage = exports.cellEditingErrorMessage = void 0;
var react_1 = require("react");
var React = require("react");
var ErrorContext = (0, react_1.createContext)(null);
exports.cellEditingErrorMessage = 'In Cell-editing mode, exit the edit mode by pressing ENTER or TAB or selecting another cell, and then try again.';
exports.internetConnectionMessage = 'Internet connection issues. Try again!';
exports.refreshErrorMessage = 'This sheet has nothing to refresh';
exports.errorMessage = [
    exports.cellEditingErrorMessage,
    exports.internetConnectionMessage,
    exports.refreshErrorMessage,
    'SUPPRESSED',
];
var isValidErrorMessage = function (value) {
    return exports.errorMessage.includes(value);
};
exports.isValidErrorMessage = isValidErrorMessage;
var GlobalErrorsProvider = function (_a) {
    var children = _a.children;
    var _b = (0, react_1.useState)(null), error = _b[0], setError = _b[1];
    (0, react_1.useEffect)(function () {
        setError(null);
    }, []);
    var setGlobalError = function (_a) {
        var error = _a.error;
        if ((0, exports.isValidErrorMessage)(error)) {
            setError(error);
            return;
        }
        if (error === 'Failed to fetch') {
            setError(exports.internetConnectionMessage);
            return;
        }
        if (error) {
            setError('SUPPRESSED');
            return;
        }
        setError(null);
    };
    return (React.createElement(ErrorContext.Provider, { value: {
            globalError: error,
            setGlobalError: setGlobalError,
        } }, children));
};
exports.GlobalErrorsProvider = GlobalErrorsProvider;
var useGlobalErrors = function () {
    var context = (0, react_1.useContext)(ErrorContext);
    /* istanbul ignore next */
    if (context === undefined) {
        throw new Error("useAuthentication must be used within a AuthenticationProvider");
    }
    return context;
};
exports.useGlobalErrors = useGlobalErrors;
