"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Expander = void 0;
var React = require("react");
var styled_components_1 = require("styled-components");
var EXPAND_SRC = "assets/filter-plus.svg";
var COLLAPSE_SRC = "assets/filter-minus.svg";
var EXPAND_SELECTED_SRC = "assets/filter-plus-selected.svg";
var COLLAPSE_SELECTED_SRC = "assets/filter-minus-selected.svg";
var ExpandImage = styled_components_1.default.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  position: relative;\n  top: 1px;\n  width: 11px;\n  margin-left: 5px;\n"], ["\n  cursor: pointer;\n  position: relative;\n  top: 1px;\n  width: 11px;\n  margin-left: 5px;\n"])));
var getUnselectedSrc = function (isExpanded) {
    return isExpanded ? COLLAPSE_SRC : EXPAND_SRC;
};
var getSelectedSrc = function (isExpanded) {
    return isExpanded ? COLLAPSE_SELECTED_SRC : EXPAND_SELECTED_SRC;
};
var getSrc = function (isExpanded, isSelected) {
    return isSelected ? getSelectedSrc(isExpanded) : getUnselectedSrc(isExpanded);
};
var Expander = function (_a) {
    var hasChildren = _a.hasChildren, isExpanded = _a.isExpanded, isSelected = _a.isSelected, _b = _a.onClick, onClick = _b === void 0 ? function () { return undefined; } : _b;
    if (!hasChildren)
        return null;
    return (React.createElement(ExpandImage, { src: getSrc(isExpanded, isSelected), alt: "expand option", onClick: onClick }));
};
exports.Expander = Expander;
var templateObject_1;
