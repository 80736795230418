"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WrappedAuthenticatedApp = exports.AuthenticatedApp = exports.history = void 0;
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var history_1 = require("history");
var routes_1 = require("./routes");
var FormSelectionProvider_1 = require("../../../taskpane/context/FormSelectionProvider");
var contexts_1 = require("../../../features/transactions-search/contexts");
var contexts_2 = require("../../../features/spreadsheet/contexts");
exports.history = (0, history_1.createHashHistory)();
var WrappedTrends = function () {
    return (React.createElement(contexts_2.SpreadsheetDepsProvider, null,
        React.createElement(contexts_1.TransactionSearchDepsProvider, null,
            React.createElement(FormSelectionProvider_1.FormSelectionProvider, null,
                React.createElement(routes_1.Trends, null)))));
};
var WrappedTransactions = function () {
    return React.createElement("div", null);
};
var AuthenticatedApp = function () {
    return (React.createElement(react_router_dom_1.Router, { history: exports.history },
        React.createElement(react_router_dom_1.Route, { render: function (_a) {
                var location = _a.location;
                return (React.createElement(react_router_dom_1.Switch, { location: location },
                    React.createElement(react_router_dom_1.Route, { component: function () { return React.createElement(react_router_dom_1.Redirect, { to: "/trends" }); }, exact: true, path: "/" }),
                    React.createElement(react_router_dom_1.Route, { component: WrappedTrends, path: "/trends" }),
                    React.createElement(react_router_dom_1.Route, { component: WrappedTransactions, path: "/transactions" })));
            } })));
};
exports.AuthenticatedApp = AuthenticatedApp;
var WrappedAuthenticatedApp = function () {
    return exports.AuthenticatedApp;
};
exports.WrappedAuthenticatedApp = WrappedAuthenticatedApp;
