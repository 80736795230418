"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingDropdown = void 0;
var React = require("react");
var styled_components_1 = require("styled-components");
var shared_ui_components_1 = require("@rcanalytics/shared-ui-components");
var SettingDropdownContainer = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  margin-bottom: 5px;\n  li {\n    background-color: #f5f5f5;\n  }\n\n  .drop-down-list-item:focus {\n    background-color: #0065a4 !important;\n    color: #fff;\n  }\n  margin-top: 5px;\n"], ["\n  width: 100%;\n  margin-bottom: 5px;\n  li {\n    background-color: #f5f5f5;\n  }\n\n  .drop-down-list-item:focus {\n    background-color: #0065a4 !important;\n    color: #fff;\n  }\n  margin-top: 5px;\n"])));
var DropdownLabel = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-bottom: 5px;\n"], ["\n  padding-bottom: 5px;\n"])));
var dropdownTheme = {
    iconColor: '#0065A4',
    borderColor: '#e5e5e5',
    placeholderColor: '#242424',
    selectedOption: { font: { color: '#333' } },
    list: {
    // alternating: {
    //   order: 0,
    //   backgroundColor: `transparent`,
    // },
    // hover: {
    //   backgroundColor: '#0065A4',
    //   fontColor: '#FFF',
    // },
    },
};
var SettingDropdown = function (_a) {
    var label = _a.label, placeholder = _a.placeholder, valuePropertyName = _a.valuePropertyName, _b = _a.labelPropertyName, labelPropertyName = _b === void 0 ? 'label' : _b, options = _a.options, onChange = _a.onChange;
    var displayProperties = {
        valuePropertyName: valuePropertyName,
        labelPropertyName: labelPropertyName,
    };
    return (React.createElement(SettingDropdownContainer, null,
        React.createElement(DropdownLabel, null,
            React.createElement("label", null, label)),
        React.createElement(shared_ui_components_1.DropDown, { placeholder: placeholder, displayProperties: displayProperties, options: options, onChange: onChange, theme: dropdownTheme })));
};
exports.SettingDropdown = SettingDropdown;
var templateObject_1, templateObject_2;
