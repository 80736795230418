"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Spacing = exports.Line = void 0;
var styled_components_1 = require("styled-components");
exports.Line = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #dedede;\n  opacity: 1;\n  height: 2px;\n"], ["\n  background-color: #dedede;\n  opacity: 1;\n  height: 2px;\n"])));
exports.Spacing = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: ", ";\n  opacity: 1;\n"], ["\n  height: ", ";\n  opacity: 1;\n"])), function (props) { return props.height; });
var templateObject_1, templateObject_2;
