"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckBox = void 0;
var React = require("react");
var styled_components_1 = require("styled-components");
var OuterSquare = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: white;\n  border: 1px solid black;\n  height: 9px;\n  width: 9px;\n  display: flex;\n  margin-right: 5px;\n"], ["\n  background: white;\n  border: 1px solid black;\n  height: 9px;\n  width: 9px;\n  display: flex;\n  margin-right: 5px;\n"])));
var InnerSquare = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  height: 6px;\n  width: 6px;\n  margin: auto;\n"], ["\n  background: ", ";\n  height: 6px;\n  width: 6px;\n  margin: auto;\n"])), function (props) { return (props.isSelected ? 'orange' : 'white'); });
var CheckBox = function (_a) {
    var isSelected = _a.isSelected;
    return (React.createElement(OuterSquare, null,
        React.createElement(InnerSquare, { isSelected: isSelected })));
};
exports.CheckBox = CheckBox;
var templateObject_1, templateObject_2;
