"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSettings = exports.SettingsProvider = void 0;
var React = require("react");
var react_1 = require("react");
var currency_1 = require("../../types/currency");
var rentable_area_1 = require("../../types/rentable-area");
var price_floor_1 = require("../../types/price-floor");
var global_errors_1 = require("../../../../features/global-errors/contexts/global-errors");
var DEFAULT_STATE = {
    settings: {
        currency: currency_1.currencies[0],
        rentableArea: rentable_area_1.rentableAreas[0],
        priceFloor: price_floor_1.priceFloors[0],
    },
    isLoading: true,
    isSaving: false,
    pendingSettings: null,
    error: null,
};
var reducer = function (state, action) {
    var type = action.type;
    switch (type) {
        case "LOAD": {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case "LOAD_SUCCESS": {
            var settings = action.settings;
            return __assign(__assign({}, state), { settings: __assign(__assign({}, state.settings), settings), isLoading: false, error: null });
        }
        case "SAVE": {
            var pendingSettings = action.settings;
            return __assign(__assign({}, state), { isSaving: true, pendingSettings: pendingSettings });
        }
        case "SAVE_SUCCESS": {
            var settings = action.settings;
            return __assign(__assign({}, state), { settings: settings, isSaving: false, pendingSettings: null, error: null });
        }
        case "ERROR": {
            var error = action.error;
            return __assign(__assign({}, state), { isLoading: false, isSaving: false, pendingSettings: null, error: error });
        }
        /* istanbul ignore next */
        default:
            return state;
    }
};
var SettingsContext = (0, react_1.createContext)(null);
var SettingsProvider = function (_a) {
    var settingsService = _a.settingsService, children = _a.children;
    var setGlobalError = (0, global_errors_1.useGlobalErrors)().setGlobalError;
    var _b = (0, react_1.useReducer)(reducer, DEFAULT_STATE), state = _b[0], dispatch = _b[1];
    var load = (0, react_1.useCallback)(function () { return dispatch({ type: "LOAD" }); }, []);
    var loadSuccess = (0, react_1.useCallback)(function (_a) {
        var settings = _a.settings;
        return dispatch({ type: "LOAD_SUCCESS", settings: settings });
    }, []);
    var save = (0, react_1.useCallback)(function (_a) {
        var settings = _a.settings;
        return dispatch({ type: "SAVE", settings: settings });
    }, []);
    var saveSuccess = (0, react_1.useCallback)(function (_a) {
        var settings = _a.settings;
        return dispatch({ type: "SAVE_SUCCESS", settings: settings });
    }, []);
    var error = (0, react_1.useCallback)(function (_a) {
        var error = _a.error;
        return dispatch({ type: "ERROR", error: error });
    }, []);
    var loadSettings = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var settings, _a, message;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, settingsService.fetch()];
                case 1:
                    settings = _b.sent();
                    loadSuccess({ settings: settings });
                    return [3 /*break*/, 3];
                case 2:
                    _a = _b.sent();
                    message = _a.message;
                    setGlobalError({ error: message });
                    error({ error: message });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [settingsService, loadSuccess, error]);
    (0, react_1.useEffect)(function () {
        if (state.isLoading)
            loadSettings();
    }, [state.isLoading]);
    (0, react_1.useEffect)(function () {
        load();
    }, [settingsService]);
    (0, react_1.useEffect)(function () {
        var save = function (_a) {
            var settings = _a.settings;
            return __awaiter(void 0, void 0, void 0, function () {
                var _b, message;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _c.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, settingsService.saveSettings({ settings: settings })];
                        case 1:
                            _c.sent();
                            saveSuccess({ settings: settings });
                            setGlobalError({ error: null });
                            return [3 /*break*/, 3];
                        case 2:
                            _b = _c.sent();
                            message = _b.message;
                            setGlobalError({ error: message });
                            error({ error: message });
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        if (state.isSaving)
            save({ settings: state.pendingSettings });
    }, [state.isSaving]);
    return (React.createElement(SettingsContext.Provider, { value: __assign(__assign({}, state), { load: load, save: save }) }, children));
};
exports.SettingsProvider = SettingsProvider;
var useSettings = function () {
    var context = (0, react_1.useContext)(SettingsContext);
    /* istanbul ignore next */
    if (context === undefined) {
        throw new Error("useSettings must be used within a SettingsProvider");
    }
    return context;
};
exports.useSettings = useSettings;
