"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormSelection = exports.FormSelectionProvider = exports.nonSelectableGeographies = void 0;
var react_1 = require("react");
var React = require("react");
var settings_1 = require("../../../../rca-aggregates/src/features/settings/contexts/settings");
var search_options_1 = require("../../features/search-options/contexts/search-options");
exports.nonSelectableGeographies = [
    "Zone/Theatre",
    "Country/Region",
    "Metro/Market",
];
var createTrendsDTO = function (hasMultiPropertyTypeSelection, hasMultiGeographiesSelection, hasMultiDataTypeSelection, selectedPropertyTypes, selectedGeographies, selectedDataTypes) {
    var _a, _b, _c, _d, _e;
    var _f = (0, settings_1.useSettings)().settings, currency = _f.currency, rentableArea = _f.rentableArea, priceFloor = _f.priceFloor;
    var rollingAverage = (0, search_options_1.useSearchOptions)().rollingAverage;
    var filterSettings = {
        priceFloor: priceFloor,
        currency: currency,
        rentableArea: rentableArea,
        rollingAverage: rollingAverage,
    };
    if (hasMultiPropertyTypeSelection) {
        return {
            series: selectedPropertyTypes.map(function (selectedPropertyType) {
                var _a, _b, _c, _d;
                return {
                    geographyFilter: {
                        id: (_a = selectedGeographies[0]) === null || _a === void 0 ? void 0 : _a.value.id,
                        type: (_b = selectedGeographies[0]) === null || _b === void 0 ? void 0 : _b.value.type,
                        name: (_c = selectedGeographies[0]) === null || _c === void 0 ? void 0 : _c.value.name,
                    },
                    propertyTypes: [selectedPropertyType === null || selectedPropertyType === void 0 ? void 0 : selectedPropertyType.id],
                    aggregation: __assign({ dataType: (_d = selectedDataTypes[0]) === null || _d === void 0 ? void 0 : _d.id }, filterSettings),
                };
            }),
        };
    }
    else if (hasMultiGeographiesSelection) {
        return {
            series: selectedGeographies.map(function (selectedGeography) {
                var _a, _b;
                return {
                    geographyFilter: {
                        id: selectedGeography === null || selectedGeography === void 0 ? void 0 : selectedGeography.value.id,
                        type: selectedGeography === null || selectedGeography === void 0 ? void 0 : selectedGeography.value.type,
                        name: selectedGeography === null || selectedGeography === void 0 ? void 0 : selectedGeography.value.name,
                    },
                    propertyTypes: [(_a = selectedPropertyTypes[0]) === null || _a === void 0 ? void 0 : _a.id],
                    aggregation: __assign({ dataType: (_b = selectedDataTypes[0]) === null || _b === void 0 ? void 0 : _b.id }, filterSettings),
                };
            }),
        };
    }
    else if (hasMultiDataTypeSelection) {
        return {
            series: selectedDataTypes.map(function (selectedDataType) {
                var _a, _b, _c, _d;
                return {
                    geographyFilter: {
                        id: (_a = selectedGeographies[0]) === null || _a === void 0 ? void 0 : _a.value.id,
                        type: (_b = selectedGeographies[0]) === null || _b === void 0 ? void 0 : _b.value.type,
                        name: (_c = selectedGeographies[0]) === null || _c === void 0 ? void 0 : _c.value.name,
                    },
                    propertyTypes: [(_d = selectedPropertyTypes[0]) === null || _d === void 0 ? void 0 : _d.id],
                    aggregation: __assign({ dataType: selectedDataType === null || selectedDataType === void 0 ? void 0 : selectedDataType.id }, filterSettings),
                };
            }),
        };
    }
    else {
        return {
            series: [
                {
                    geographyFilter: {
                        id: (_a = selectedGeographies[0]) === null || _a === void 0 ? void 0 : _a.value.id,
                        type: (_b = selectedGeographies[0]) === null || _b === void 0 ? void 0 : _b.value.type,
                        name: (_c = selectedGeographies[0]) === null || _c === void 0 ? void 0 : _c.value.name,
                    },
                    propertyTypes: [(_d = selectedPropertyTypes[0]) === null || _d === void 0 ? void 0 : _d.id],
                    aggregation: __assign({ dataType: (_e = selectedDataTypes[0]) === null || _e === void 0 ? void 0 : _e.id }, filterSettings),
                },
            ],
        };
    }
};
var FormSelectionContext = (0, react_1.createContext)(null);
var FormSelectionProvider = function (props) {
    var _a = (0, react_1.useState)([]), slugsFromPropertyTypes = _a[0], setSlugsFromPropertyTypes = _a[1];
    var _b = (0, react_1.useState)([]), selectedPropertyTypes = _b[0], setSelectedPropertyTypes = _b[1];
    var _c = (0, react_1.useState)([]), selectedGeographies = _c[0], setSelectedGeographies = _c[1];
    var _d = (0, react_1.useState)([]), selectedDataTypes = _d[0], setSelectedDataTypes = _d[1];
    var handleSelectGeographies = function (_a) {
        var selectedGeographies = _a.selectedGeographies;
        var values = selectedGeographies === null || selectedGeographies === void 0 ? void 0 : selectedGeographies.filter(function (item) { return !exports.nonSelectableGeographies.includes(item.label); });
        setSelectedGeographies(values);
    };
    var handleSelectDataTypes = function (_a) {
        var selectedDataTypes = _a.selectedDataTypes;
        setSelectedDataTypes(selectedDataTypes);
    };
    var handleSelectPropertyTypes = function (_a) {
        var selectedPropertyTypes = _a.selectedPropertyTypes;
        var slugsFromPropertyTypes = (selectedPropertyTypes === null || selectedPropertyTypes === void 0 ? void 0 : selectedPropertyTypes.length) > 0
            ? selectedPropertyTypes.map(function (selectedPropertyType) { return selectedPropertyType.id; })
            : [];
        setSelectedPropertyTypes(selectedPropertyTypes);
        setSlugsFromPropertyTypes(slugsFromPropertyTypes);
    };
    var hasMultiPropertyTypeSelection = (selectedGeographies === null || selectedGeographies === void 0 ? void 0 : selectedGeographies.length) <= 1 && (selectedDataTypes === null || selectedDataTypes === void 0 ? void 0 : selectedDataTypes.length) <= 1;
    var hasMultiGeographiesSelection = (selectedPropertyTypes === null || selectedPropertyTypes === void 0 ? void 0 : selectedPropertyTypes.length) <= 1 && (selectedDataTypes === null || selectedDataTypes === void 0 ? void 0 : selectedDataTypes.length) <= 1;
    var hasMultiDataTypeSelection = (selectedPropertyTypes === null || selectedPropertyTypes === void 0 ? void 0 : selectedPropertyTypes.length) <= 1 && (selectedGeographies === null || selectedGeographies === void 0 ? void 0 : selectedGeographies.length) <= 1;
    var shouldPropertyTypeBeDisabled = (selectedGeographies === null || selectedGeographies === void 0 ? void 0 : selectedGeographies.length) < 1;
    var shouldDataTypeBeDisabled = (selectedPropertyTypes === null || selectedPropertyTypes === void 0 ? void 0 : selectedPropertyTypes.length) < 1;
    var shouldGetDataBeDisabled = (selectedDataTypes === null || selectedDataTypes === void 0 ? void 0 : selectedDataTypes.length) < 1;
    var trendsDto = createTrendsDTO(hasMultiPropertyTypeSelection, hasMultiGeographiesSelection, hasMultiDataTypeSelection, selectedPropertyTypes, selectedGeographies, selectedDataTypes);
    return (React.createElement(FormSelectionContext.Provider, __assign({ value: {
            handleSelectGeographies: handleSelectGeographies,
            handleSelectDataTypes: handleSelectDataTypes,
            handleSelectPropertyTypes: handleSelectPropertyTypes,
            selectedDataTypes: selectedDataTypes,
            selectedPropertyTypes: selectedPropertyTypes,
            selectedGeographies: selectedGeographies,
            setSelectedPropertyTypes: setSelectedPropertyTypes,
            setSelectedDataTypes: setSelectedDataTypes,
            hasMultiPropertyTypeSelection: hasMultiPropertyTypeSelection,
            hasMultiGeographiesSelection: hasMultiGeographiesSelection,
            hasMultiDataTypeSelection: hasMultiDataTypeSelection,
            shouldPropertyTypeBeDisabled: shouldPropertyTypeBeDisabled,
            shouldDataTypeBeDisabled: shouldDataTypeBeDisabled,
            slugsFromPropertyTypes: slugsFromPropertyTypes,
            trendsDto: trendsDto,
            shouldGetDataBeDisabled: shouldGetDataBeDisabled,
        } }, props)));
};
exports.FormSelectionProvider = FormSelectionProvider;
var useFormSelection = function () {
    var context = (0, react_1.useContext)(FormSelectionContext);
    if (context === undefined) {
        throw new Error("useFormSelection must be used within a FormSelectionProvider");
    }
    return context;
};
exports.useFormSelection = useFormSelection;
