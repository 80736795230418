"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var styled_components_1 = require("styled-components");
var designTokens_1 = require("../../../../designTokens");
var context_1 = require("../../../../../taskpane/context");
var ActionsContainer = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 40px 0;\n"], ["\n  margin: 40px 0;\n"])));
var Action = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
var Button = styled_components_1.default.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: none;\n  cursor: ", ";\n  background: ", ";\n  opacity: ", ";\n  width: 100%;\n  font-size: 18px;\n  font-family: Roboto !important;\n  color: white;\n  text-align: center;\n  padding: 10px;\n  font-weight: 1000;\n  &:hover {\n    ", ";\n  }\n"], ["\n  border: none;\n  cursor: ", ";\n  background: ", ";\n  opacity: ", ";\n  width: 100%;\n  font-size: 18px;\n  font-family: Roboto !important;\n  color: white;\n  text-align: center;\n  padding: 10px;\n  font-weight: 1000;\n  &:hover {\n    ", ";\n  }\n"])), function (_a) {
    var isDisabled = _a.isDisabled;
    return (isDisabled ? 'auto' : 'pointer');
}, designTokens_1.designTokens.menuBlueSelected, function (_a) {
    var isDisabled = _a.isDisabled;
    return (isDisabled ? 0.5 : 1);
}, function (_a) {
    var isDisabled = _a.isDisabled;
    return !isDisabled && 'filter: brightness(115%)';
});
var DEFAULT_ON_GET_DATA_CLICK = function () { return undefined; };
var Actions = function (_a) {
    var _b = _a.onGetDataClick, onGetDataClick = _b === void 0 ? DEFAULT_ON_GET_DATA_CLICK : _b, isTransactionLoading = _a.isTransactionLoading;
    var shouldGetDataBeDisabled = (0, context_1.useFormSelection)().shouldGetDataBeDisabled;
    var isDisabled = isTransactionLoading || shouldGetDataBeDisabled;
    return (React.createElement(ActionsContainer, null,
        React.createElement(Action, null,
            React.createElement(Button, { "data-testid": "Get Data", onClick: onGetDataClick, isDisabled: isDisabled }, "Get Data"))));
};
exports.default = Actions;
var templateObject_1, templateObject_2, templateObject_3;
