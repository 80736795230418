"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tabs = void 0;
var React = require("react");
var styled_components_1 = require("styled-components");
var geographies_state_1 = require("../../contexts/geographies-state");
var ZONES = ["Americas", "EMEA", "AsiaPac"];
var Container = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  background: #dedede;\n  padding: 10px 14px;\n\n  & > * + * {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  background: #dedede;\n  padding: 10px 14px;\n\n  & > * + * {\n    margin-left: 10px;\n  }\n"])));
var selectedTabContainer = (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: bold;\n  background: #0065a4;\n  color: white;\n"], ["\n  font-weight: bold;\n  background: #0065a4;\n  color: white;\n"])));
var unselectedTabContainer = (0, styled_components_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: white;\n  color: #0065a4;\n"], ["\n  background: white;\n  color: #0065a4;\n"])));
var TabContainer = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n\n  font-size: 16px;\n  border-radius: 3px;\n  flex: 1;\n\n  input {\n    display: none;\n  }\n"], ["\n  ", ";\n\n  font-size: 16px;\n  border-radius: 3px;\n  flex: 1;\n\n  input {\n    display: none;\n  }\n"])), function (_a) {
    var isSelected = _a.isSelected;
    return isSelected ? selectedTabContainer : unselectedTabContainer;
});
var Label = styled_components_1.default.label(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-align: center;\n  cursor: pointer;\n  display: block;\n  padding: 6px;\n"], ["\n  text-align: center;\n  cursor: pointer;\n  display: block;\n  padding: 6px;\n"])));
var Tab = function (_a) {
    var zone = _a.zone, selected = _a.selected, onChange = _a.onChange;
    var isSelected = selected === zone;
    return (React.createElement(TabContainer, { isSelected: isSelected },
        React.createElement("input", { type: "radio", name: "zones", value: zone, id: zone, onChange: onChange, checked: selected === zone }),
        React.createElement(Label, { htmlFor: zone }, zone)));
};
var Tabs = function () {
    var _a = (0, geographies_state_1.useGeographiesState)().zones, selectedZone = _a.zone, setZone = _a.setZone;
    var onChange = function (_a) {
        var value = _a.target.value;
        setZone({ zone: value });
    };
    var tabs = ZONES.map(function (zone) { return (React.createElement(Tab, { key: zone, zone: zone, selected: selectedZone, onChange: onChange })); });
    return React.createElement(Container, null, tabs);
};
exports.Tabs = Tabs;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
