"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toDataTypeOptions = void 0;
var toDataSubTypeOptions = function (dataSubTypes) {
    return dataSubTypes.map(function (dataSubType) {
        var id = dataSubType.slug, label = dataSubType.label;
        return {
            id: id,
            label: label,
        };
    });
};
var toDataTypeOptions = function (dataTypes) {
    return dataTypes.map(function (dataType) {
        var dataSubTypes = dataType.dataSubTypes, id = dataType.slug, label = dataType.label;
        var options = toDataSubTypeOptions(dataSubTypes);
        return {
            id: id,
            label: label,
            options: options,
        };
    });
};
exports.toDataTypeOptions = toDataTypeOptions;
