"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSpreadsheetDeps = exports.SpreadsheetDepsProvider = void 0;
var React = require("react");
var react_1 = require("react");
var excel_1 = require("../../../providers/excel");
var spreadsheet_1 = require("../services/spreadsheet");
var SpreadsheetContext = (0, react_1.createContext)({ spreadsheetService: null });
var SpreadsheetDepsProvider = function (_a) {
    var children = _a.children;
    var excelProvider = (0, excel_1.createExcelProvider)();
    var spreadsheetService = (0, spreadsheet_1.createSpreadsheetService)({
        excelProvider: excelProvider,
    });
    return (React.createElement(SpreadsheetContext.Provider, { value: { spreadsheetService: spreadsheetService } }, children));
};
exports.SpreadsheetDepsProvider = SpreadsheetDepsProvider;
var useSpreadsheetDeps = function () {
    return (0, react_1.useContext)(SpreadsheetContext);
};
exports.useSpreadsheetDeps = useSpreadsheetDeps;
