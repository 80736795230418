"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createExcelProvider = void 0;
var table_range_1 = require("../../features/spreadsheet/helpers/table-range");
var excelProvider = function () {
    var createTable = function (_a) {
        var context = _a.context, worksheet = _a.worksheet, _b = _a.tableConfig, range = _b.range, data = _b.data, numberFormats = _b.numberFormats;
        return __awaiter(void 0, void 0, void 0, function () {
            var table;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        table = worksheet.tables.add(range, false);
                        table.showHeaders = false;
                        table.style = null;
                        table.rows.add(0, data);
                        if (numberFormats) {
                            table.getDataBodyRange().numberFormat = numberFormats;
                        }
                        return [4 /*yield*/, context.sync()];
                    case 1:
                        _c.sent();
                        table.load('id');
                        return [4 /*yield*/, context.sync()];
                    case 2:
                        _c.sent();
                        return [2 /*return*/, table.id];
                }
            });
        });
    };
    var autoFitCells = function (_a) {
        var context = _a.context;
        if (Office.context.requirements.isSetSupported('ExcelApi', '1.2')) {
            var worksheet = context.workbook.worksheets.getActiveWorksheet();
            worksheet.getUsedRange().format.autofitColumns();
            worksheet.getUsedRange().format.autofitRows();
        }
    };
    return {
        clearCurrentWorksheet: function () {
            Excel.run(function (context) { return __awaiter(void 0, void 0, void 0, function () {
                var worksheet;
                return __generator(this, function (_a) {
                    worksheet = context.workbook.worksheets.getActiveWorksheet();
                    worksheet.getRange().clear();
                    return [2 /*return*/, context.sync()];
                });
            }); });
        },
        createTable: function (_a) {
            var tableConfig = _a.tableConfig;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, Excel.run(function (context) { return __awaiter(void 0, void 0, void 0, function () {
                                var worksheet, tableId;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            worksheet = context.workbook.worksheets.getActiveWorksheet();
                                            return [4 /*yield*/, createTable({
                                                    context: context,
                                                    worksheet: worksheet,
                                                    tableConfig: tableConfig,
                                                })];
                                        case 1:
                                            tableId = _a.sent();
                                            autoFitCells({ context: context });
                                            return [4 /*yield*/, context.sync()];
                                        case 2:
                                            _a.sent();
                                            return [2 /*return*/, tableId];
                                    }
                                });
                            }); })];
                        case 1: return [2 /*return*/, _b.sent()];
                    }
                });
            });
        },
        deleteTable: function (_a) {
            var excelTableId = _a.excelTableId;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, Excel.run(function (context) { return __awaiter(void 0, void 0, void 0, function () {
                                var sheet, table;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            sheet = context.workbook.worksheets.getActiveWorksheet();
                                            table = sheet.tables.getItem(excelTableId);
                                            table.delete();
                                            return [4 /*yield*/, context.sync()];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                        case 1: return [2 /*return*/, _b.sent()];
                    }
                });
            });
        },
        deleteRange: function (_a) {
            var activeRow = _a.activeRow, activeColumn = _a.activeColumn, rangeRows = _a.rangeRows;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, Excel.run(function (context) { return __awaiter(void 0, void 0, void 0, function () {
                                var sheet, rangeToDelete, range;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            sheet = context.workbook.worksheets.getActiveWorksheet();
                                            rangeToDelete = "".concat((0, table_range_1.getHeaderRange)({
                                                activeColumn: activeColumn,
                                                activeRow: activeRow,
                                                rangeRows: rangeRows,
                                            }));
                                            range = sheet.getRange(rangeToDelete);
                                            range.delete('Up');
                                            return [4 /*yield*/, context.sync()];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                        case 1: return [2 /*return*/, _b.sent()];
                    }
                });
            });
        },
        updateHeaderTableBodyRange: function (_a) {
            var headersData = _a.headersData, firstRow = _a.firstRow, firstColumn = _a.firstColumn;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, Excel.run(function (context) { return __awaiter(void 0, void 0, void 0, function () {
                                var sheet, tableRange;
                                return __generator(this, function (_a) {
                                    sheet = context.workbook.worksheets.getActiveWorksheet();
                                    tableRange = sheet.getRange((0, table_range_1.getTableRange)({
                                        columnLength: headersData[0].length,
                                        rowLength: headersData.length,
                                        firstRow: firstRow,
                                        firstColumn: firstColumn,
                                    }));
                                    tableRange.values = headersData;
                                    return [2 /*return*/];
                                });
                            }); })];
                        case 1: return [2 /*return*/, _b.sent()];
                    }
                });
            });
        },
        updateTableDataBodyRange: function (_a) {
            var data = _a.data, firstRow = _a.firstRow, firstColumn = _a.firstColumn;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, Excel.run(function (context) { return __awaiter(void 0, void 0, void 0, function () {
                                var sheet, tableBodyFirstRow, tableRange;
                                return __generator(this, function (_a) {
                                    sheet = context.workbook.worksheets.getActiveWorksheet();
                                    tableBodyFirstRow = firstRow;
                                    tableRange = sheet.getRange((0, table_range_1.getTableRange)({
                                        columnLength: data[0].length,
                                        rowLength: data.length,
                                        firstRow: tableBodyFirstRow,
                                        firstColumn: firstColumn,
                                    }));
                                    tableRange.values = data;
                                    return [2 /*return*/];
                                });
                            }); })];
                        case 1: return [2 /*return*/, _b.sent()];
                    }
                });
            });
        },
        getTableFirstColumnAndRowValue: function (_a) {
            var excelTableId = _a.excelTableId;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, Excel.run(function (context) { return __awaiter(void 0, void 0, void 0, function () {
                                var sheet, table, tableRange, address, rangeAddress, firstCellOfRangeAddress, firstColumn, firstRow;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            sheet = context.workbook.worksheets.getActiveWorksheet();
                                            table = sheet.tables.getItem(excelTableId.replace('_searchId', ''));
                                            tableRange = table.getRange();
                                            tableRange.load('address');
                                            return [4 /*yield*/, context.sync()];
                                        case 1:
                                            _a.sent();
                                            address = tableRange.address;
                                            rangeAddress = address.substring(address.indexOf('!') + 1, address.length);
                                            firstCellOfRangeAddress = rangeAddress.substring(0, rangeAddress.indexOf(':'));
                                            firstColumn = firstCellOfRangeAddress.replace(/\d+/g, '');
                                            firstRow = parseInt(firstCellOfRangeAddress.replace(/\D/g, ''));
                                            return [2 /*return*/, { firstRow: firstRow, firstColumn: firstColumn }];
                                    }
                                });
                            }); })];
                        case 1: return [2 /*return*/, _b.sent()];
                    }
                });
            });
        },
        createHeader: function (_a) {
            var data = _a.data, range = _a.range;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, Excel.run(function (context) {
                                var sheet = context.workbook.worksheets.getActiveWorksheet();
                                var dataRange = sheet.getRange(range);
                                dataRange.values = data;
                                autoFitCells({ context: context });
                                return context.sync();
                            })];
                        case 1: return [2 /*return*/, _b.sent()];
                    }
                });
            });
        },
        removeCustomProperty: function (_a) {
            var key = _a.key;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, Excel.run(function (context) { return __awaiter(void 0, void 0, void 0, function () {
                                var workbookCustomProperties, customProperty;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            workbookCustomProperties = context.workbook.properties.custom;
                                            customProperty = workbookCustomProperties.getItem(key);
                                            return [4 /*yield*/, context.sync()];
                                        case 1:
                                            _a.sent();
                                            customProperty.delete();
                                            return [4 /*yield*/, context.sync()];
                                        case 2:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                        case 1: return [2 /*return*/, _b.sent()];
                    }
                });
            });
        },
        createCustomProperty: function (_a) {
            var key = _a.key, value = _a.value;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, Excel.run(function (context) { return __awaiter(void 0, void 0, void 0, function () {
                                var workbookCustomProperties;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            workbookCustomProperties = context.workbook.properties.custom;
                                            workbookCustomProperties.add(key, value);
                                            return [4 /*yield*/, context.sync()];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/, { key: key, value: value }];
                                    }
                                });
                            }); })];
                        case 1: return [2 /*return*/, _b.sent()];
                    }
                });
            });
        },
        getActiveColumnAndRowValue: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Excel.run(function (context) { return __awaiter(void 0, void 0, void 0, function () {
                            var cell, e_1, selectedCellAddress, activeColumn, activeRow;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        cell = context.workbook.getActiveCell();
                                        cell.load('address');
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 3, , 4]);
                                        return [4 /*yield*/, context.sync()];
                                    case 2:
                                        _a.sent();
                                        return [3 /*break*/, 4];
                                    case 3:
                                        e_1 = _a.sent();
                                        //catch it here otherwise nextline will instead throw error.
                                        throw new Error(e_1);
                                    case 4:
                                        selectedCellAddress = cell.address;
                                        selectedCellAddress = selectedCellAddress.substring(selectedCellAddress.indexOf('!') + 1);
                                        activeColumn = selectedCellAddress.replace(/\d+/g, '');
                                        activeRow = parseInt(selectedCellAddress.replace(/\D/g, ''));
                                        return [2 /*return*/, { activeRow: activeRow, activeColumn: activeColumn }];
                                }
                            });
                        }); })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); },
        getCustomPropertyValue: function (_a) {
            var key = _a.key;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, Excel.run(function (context) { return __awaiter(void 0, void 0, void 0, function () {
                                var customProperties, customProperty;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            customProperties = context.workbook.properties.custom;
                                            return [4 /*yield*/, customProperties
                                                    .getItemOrNullObject(key)
                                                    .load({
                                                    value: true,
                                                })];
                                        case 1:
                                            customProperty = _a.sent();
                                            return [4 /*yield*/, context.sync()];
                                        case 2:
                                            _a.sent();
                                            return [2 /*return*/, customProperty.value];
                                    }
                                });
                            }); })];
                        case 1: return [2 /*return*/, _b.sent()];
                    }
                });
            });
        },
        getTableIdsInActiveWorksheet: function () { return __awaiter(void 0, void 0, void 0, function () {
            var tableIds;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tableIds = [];
                        return [4 /*yield*/, Excel.run(function (context) { return __awaiter(void 0, void 0, void 0, function () {
                                var currentWorksheet;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
                                            currentWorksheet.tables.load('items');
                                            return [4 /*yield*/, context.sync()];
                                        case 1:
                                            _a.sent();
                                            currentWorksheet.tables.items.forEach(function (item) {
                                                tableIds.push(item.id);
                                            });
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, tableIds];
                }
            });
        }); },
    };
};
var createExcelProvider = function () { return excelProvider(); };
exports.createExcelProvider = createExcelProvider;
