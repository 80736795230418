"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedFeatureFlag = void 0;
var React = require("react");
var FeatureFlag_1 = require("../../../components/FeatureFlag");
var launchdarkly_1 = require("../../../hooks/launchdarkly");
var feature_flag_1 = require("../../../hooks/feature-flag");
var show_feature_1 = require("../../../hooks/show-feature");
var authentication_1 = require("../../../../authentication");
var configuration_1 = require("../../../../configuration");
var ConnectedFeatureFlag = function (_a) {
    var flag = _a.flag, _b = _a.showFeatureWhenFalse, showFeatureWhenFalse = _b === void 0 ? false : _b, children = _a.children;
    var _c = (0, configuration_1.useConfiguration)(), launchdarklyClientId = _c.launchdarklyClientId, isConfigurationLoading = _c.isLoading;
    var username = (0, authentication_1.useAuthentication)().usernameFromToken;
    var _d = (0, launchdarkly_1.useLaunchdarkly)({
        username: username,
        clientId: launchdarklyClientId,
    }), launchdarkly = _d.launchdarkly, isLoadingLaunchdarkly = _d.isLoading;
    var featureFlag = (0, feature_flag_1.useFeatureFlag)({
        launchdarkly: launchdarkly,
    }).featureFlag;
    var showFeature = (0, show_feature_1.useShowFeature)({ featureFlag: featureFlag, flag: flag }).showFeature;
    if (isConfigurationLoading ||
        isLoadingLaunchdarkly ||
        launchdarklyClientId === null)
        return null;
    return (React.createElement(FeatureFlag_1.FeatureFlag, { showFeature: showFeatureWhenFalse ? !showFeature : showFeature }, children));
};
exports.ConnectedFeatureFlag = ConnectedFeatureFlag;
