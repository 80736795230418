"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedSearchOptionsProvider = void 0;
var React = require("react");
var search_options_1 = require("../../../search-options");
var ConnectedSearchOptionsProvider = function (_a) {
    var children = _a.children;
    return (React.createElement(search_options_1.SearchOptionsProvider, null, children));
};
exports.ConnectedSearchOptionsProvider = ConnectedSearchOptionsProvider;
