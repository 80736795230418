"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedGeographies = void 0;
var React = require("react");
var react_1 = require("react");
var Geographies_1 = require("../../../components/Geographies");
var geographies_1 = require("../../../services/geographies");
var constants_1 = require("../../../../constants");
var authentication_1 = require("../../../../authentication");
var configuration_1 = require("../../../../configuration");
var cd_product_api_1 = require("../../../../../providers/cd-product-api");
var ConnectedGeographies = function (_a) {
    var hasMultiSelect = _a.hasMultiSelect, onChange = _a.onChange;
    var baseServer = (0, configuration_1.useConfiguration)().baseServer;
    var accessToken = (0, authentication_1.useAuthentication)().accessToken;
    var cdProductAPIProvider = (0, react_1.useMemo)(function () {
        return (0, cd_product_api_1.createCDProductAPIProvider)({
            basePath: constants_1.GEOGRAPHY_BASE_PATH,
            baseServer: baseServer,
            accessToken: accessToken,
        });
    }, [accessToken]);
    var geographiesService = (0, react_1.useMemo)(function () {
        return (0, geographies_1.createGeographiesService)({
            cdProductAPIProvider: cdProductAPIProvider,
        });
    }, [cdProductAPIProvider]);
    return (React.createElement(Geographies_1.Geographies, { geographiesService: geographiesService, onChange: onChange, hasMultiSelect: hasMultiSelect }));
};
exports.ConnectedGeographies = ConnectedGeographies;
