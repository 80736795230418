"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.priceFloorLabels = exports.priceFloors = void 0;
exports.priceFloors = [
    "US_2_5_GLOBAL_10",
    "US_5_GLOBAL_10",
    "ALL_10",
    "ALL_25",
    "ALL_50",
    "ALL_100",
];
exports.priceFloorLabels = {
    US_2_5_GLOBAL_10: '2.5 million, US 10 million Global',
    US_5_GLOBAL_10: '5 million, US 10 million Global',
    ALL_10: '10 million',
    ALL_25: '25 million',
    ALL_50: '50 million',
    ALL_100: '100 million',
};
