"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ASIA_PAC = exports.EMEA = exports.AMERICAS = exports.AsiaPacZoneName = exports.EmeaZoneName = exports.AmericasZoneName = exports.GlobalGeographyName = exports.MetroMarketName = exports.CountryRegionName = exports.ZoneTheatreName = void 0;
exports.ZoneTheatreName = "Zone/Theatre";
exports.CountryRegionName = "Country/Region";
exports.MetroMarketName = "Metro/Market";
exports.GlobalGeographyName = "Global";
// deprecate
exports.AmericasZoneName = "Americas";
exports.EmeaZoneName = "EMEA";
exports.AsiaPacZoneName = "AsiaPac";
exports.AMERICAS = "Americas";
exports.EMEA = "EMEA";
exports.ASIA_PAC = "AsiaPac";
