"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createFeatureFlag = void 0;
var featureFlag = function (_a) {
    var launchdarkly = _a.launchdarkly;
    return ({
        get: function (_a) {
            var flag = _a.flag;
            var feature = launchdarkly.variation(flag, false);
            return feature;
        },
        onChange: function (_a) {
            var flag = _a.flag, changeListener = _a.changeListener;
            launchdarkly.on("change", function () {
                var feature = launchdarkly.variation(flag, false);
                changeListener({ feature: feature });
            });
        },
    });
};
var createFeatureFlag = function (_a) {
    var launchdarkly = _a.launchdarkly;
    return featureFlag({ launchdarkly: launchdarkly });
};
exports.createFeatureFlag = createFeatureFlag;
