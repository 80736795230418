"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopLayerOption = void 0;
var React = require("react");
var styled_components_1 = require("styled-components");
var DefaultOption = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: bold;\n  padding: 2px;\n  padding-left: 5px;\n  cursor: pointer;\n"], ["\n  font-weight: bold;\n  padding: 2px;\n  padding-left: 5px;\n  cursor: pointer;\n"])));
var UnselectableOption = (0, styled_components_1.default)(DefaultOption)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: default;\n"], ["\n  cursor: default;\n"])));
var UnselectedOption = (0, styled_components_1.default)(DefaultOption)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  &:hover {\n    background: #f5f5f5;\n    filter: brightness(95%);\n  }\n"], ["\n  &:hover {\n    background: #f5f5f5;\n    filter: brightness(95%);\n  }\n"])));
var SelectedOption = (0, styled_components_1.default)(DefaultOption)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: #0065a4;\n  color: white;\n\n  &:hover {\n    background: #0065a4;\n  }\n"], ["\n  background: #0065a4;\n  color: white;\n\n  &:hover {\n    background: #0065a4;\n  }\n"])));
var getOption = function (id, isSelected) {
    if (id === "volume" || id === "pricing")
        return UnselectableOption;
    return isSelected ? SelectedOption : UnselectedOption;
};
var TopLayerOption = function (_a) {
    var id = _a.id, label = _a.label, isSelected = _a.isSelected;
    var Option = getOption(id, isSelected);
    return React.createElement(Option, null, label);
};
exports.TopLayerOption = TopLayerOption;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
