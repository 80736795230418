"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChildOption = void 0;
var React = require("react");
var react_1 = require("react");
var styled_components_1 = require("styled-components");
var Expander_1 = require("../Expander");
var geographies_state_1 = require("../../contexts/geographies-state");
var CheckBox_1 = require("../../../../../../components/PickList/components/CheckBox");
var DefaultOption = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 2px;\n  padding-left: ", "px;\n  background: transparent;\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n  opacity: ", ";\n  cursor: ", ";\n\n  &:hover {\n    background: #f5f5f5;\n    filter: brightness(95%);\n  }\n"], ["\n  padding: 2px;\n  padding-left: ", "px;\n  background: transparent;\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n  opacity: ", ";\n  cursor: ", ";\n\n  &:hover {\n    background: #f5f5f5;\n    filter: brightness(95%);\n  }\n"])), function (_a) {
    var paddingLeft = _a.paddingLeft;
    return paddingLeft;
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? "20%" : "100%");
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? "default" : "point");
});
var SelectedOption = (0, styled_components_1.default)(DefaultOption)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: #0065a4;\n  color: white;\n\n  &:hover {\n    background: #0065a4;\n  }\n"], ["\n  background: #0065a4;\n  color: white;\n\n  &:hover {\n    background: #0065a4;\n  }\n"])));
var TopLabel = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: bold;\n"], ["\n  font-weight: bold;\n"])));
var ChildLabel = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var ChildOption = function (_a) {
    var label = _a.label, isSelected = _a.isSelected, layer = _a.layer, selectType = _a.selectType, hasChildren = _a.hasChildren, id = _a.id, isEnabled = _a.value.isEnabled;
    var ref = (0, react_1.useRef)(null);
    var _b = (0, geographies_state_1.useGeographiesState)().expander, isExpanded = _b.isExpanded, expand = _b.expand, collapse = _b.collapse;
    var Label = layer === 0 ? TopLabel : ChildLabel;
    var Option = isSelected ? SelectedOption : DefaultOption;
    var paddingLeft = layer === 0 ? 5 : layer * 20;
    var onClickExpander = function (event) {
        event.stopPropagation();
        if (isExpanded({ id: id }))
            collapse({ id: id });
        else
            expand({ id: id });
    };
    var onClickOption = function (event) {
        if (isEnabled)
            return;
        event.stopPropagation();
    };
    return (React.createElement(Option, { ref: ref, onClick: onClickOption, paddingLeft: paddingLeft, disabled: !isEnabled },
        selectType === "multi" && React.createElement(CheckBox_1.CheckBox, { isSelected: isSelected }),
        React.createElement(Label, null, label),
        React.createElement(Expander_1.Expander, { isSelected: isSelected, hasChildren: hasChildren, onClick: onClickExpander, isExpanded: isExpanded({ id: id }) })));
};
exports.ChildOption = ChildOption;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
