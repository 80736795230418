"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentableAreaSettings = void 0;
var React = require("react");
var SettingDropdown_1 = require("../SettingDropdown");
var rentable_area_1 = require("../../../types/rentable-area");
var rentableAreaLabelMap = {
    SQFT: 'Square Feet',
    SQMT: 'Square Meters',
};
var rentableAreaOptions = rentable_area_1.rentableAreas.map(function (rentableArea) { return ({
    rentableArea: rentableArea,
    label: rentableAreaLabelMap[rentableArea],
}); });
var RentableAreaSettings = function (_a) {
    var selectedRentableArea = _a.selectedRentableArea, onRentableAreaChange = _a.onRentableAreaChange;
    var onChange = function (option) {
        return onRentableAreaChange(option.rentableArea);
    };
    return (React.createElement(SettingDropdown_1.SettingDropdown, { label: "Display Rentable Area as", placeholder: rentableAreaLabelMap[selectedRentableArea], valuePropertyName: "rentableArea", options: rentableAreaOptions, onChange: onChange }));
};
exports.RentableAreaSettings = RentableAreaSettings;
