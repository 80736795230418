"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PickList = void 0;
var React = require("react");
var shared_ui_components_1 = require("@rcanalytics/shared-ui-components");
var styled_components_1 = require("styled-components");
var CheckBox_1 = require("./components/CheckBox");
var DefaultOption = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 2px;\n  padding-left: ", "px;\n  background: transparent;\n  display: flex;\n  align-items: center;\n  font-size: 14;\n  opacity: ", ";\n  cursor: ", ";\n\n  &:hover {\n    background: #f5f5f5;\n    filter: brightness(95%);\n  }\n"], ["\n  padding: 2px;\n  padding-left: ", "px;\n  background: transparent;\n  display: flex;\n  align-items: center;\n  font-size: 14;\n  opacity: ", ";\n  cursor: ", ";\n\n  &:hover {\n    background: #f5f5f5;\n    filter: brightness(95%);\n  }\n"])), function (_a) {
    var paddingLeft = _a.paddingLeft;
    return paddingLeft;
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? "20%" : "100%");
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? "default" : "point");
});
var SelectedOption = (0, styled_components_1.default)(DefaultOption)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: #0065a4;\n  color: white;\n\n  &:hover {\n    background: #0065a4;\n  }\n"], ["\n  background: #0065a4;\n  color: white;\n\n  &:hover {\n    background: #0065a4;\n  }\n"])));
var TopLabel = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: bold;\n"], ["\n  font-weight: bold;\n"])));
var ChildLabel = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var CustomOption = function (_a) {
    var label = _a.label, isSelected = _a.isSelected, layer = _a.layer, selectType = _a.selectType, _b = _a.isEnabled, isEnabled = _b === void 0 ? true : _b;
    var Label = layer === 0 ? TopLabel : ChildLabel;
    var Option = isSelected ? SelectedOption : DefaultOption;
    var paddingLeft = layer === 0 ? 5 : layer * 20;
    var onClickOption = function (event) {
        if (isEnabled)
            return;
        event.stopPropagation();
    };
    return (React.createElement(Option, { paddingLeft: paddingLeft, disabled: !isEnabled, onClick: onClickOption },
        selectType === "multi" && React.createElement(CheckBox_1.CheckBox, { isSelected: isSelected }),
        React.createElement(Label, null, label)));
};
var PickList = function (_a) {
    var _b = _a.selectType, selectType = _b === void 0 ? 'single' : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, selected = _a.selected, options = _a.options, _d = _a.components, components = _d === void 0 ? {} : _d, onChange = _a.onChange, onPickListClicked = _a.onPickListClicked;
    return (React.createElement("div", { onClick: onPickListClicked },
        React.createElement(shared_ui_components_1.PickList, { selectType: selectType, disabled: disabled, selected: selected, options: options, shouldCloseOnChange: false, onChange: onChange, components: __assign({ CustomOption: CustomOption }, components) })));
};
exports.PickList = PickList;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
