"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAuthentication = exports.AuthenticationProvider = void 0;
var React = require("react");
var react_1 = require("react");
// import { loginWithLocalRefreshToken } from './helpers/login';
var token_1 = require("./helpers/token");
var global_errors_1 = require("../../../../features/global-errors/contexts/global-errors");
var DEFAULT_STATE = {
    isLoggedIn: false,
    accessToken: null,
    refreshToken: null,
    error: null,
    username: null,
    isLoading: false,
};
var AuthenticationContext = (0, react_1.createContext)(null);
var AuthenticationProvider = function (_a) {
    var authenticationService = _a.authenticationService, authenticationLocalStorageService = _a.authenticationLocalStorageService, children = _a.children;
    var setGlobalError = (0, global_errors_1.useGlobalErrors)().setGlobalError;
    var _b = (0, react_1.useState)(DEFAULT_STATE), state = _b[0], setState = _b[1];
    // const loginWithRefreshToken = useCallback(async () => {
    //   setState({
    //     ...DEFAULT_STATE,
    //     isLoading: true,
    //   });
    //   const loginResult = await loginWithLocalRefreshToken({
    //     authenticationLocalStorageService,
    //     authenticationService,
    //   });
    //   if (loginResult === null) {
    //     setState({
    //       ...DEFAULT_STATE,
    //     });
    //     return;
    //   }
    //   setState({
    //     ...DEFAULT_STATE,
    //     ...loginResult,
    //     isLoggedIn: true,
    //   });
    // }, [setState, loginWithLocalRefreshToken, DEFAULT_STATE]);
    (0, react_1.useEffect)(function () {
        var username = authenticationLocalStorageService.getUsernameFromAccessToken();
        if (authenticationLocalStorageService.areBothTokensExpired()) {
            setState(__assign(__assign({}, DEFAULT_STATE), { username: username }));
        }
        // else if (authenticationLocalStorageService.isAccessTokenExpired()) {
        //   loginWithRefreshToken();
        // }
        else {
            var accessToken = authenticationLocalStorageService.getAccessToken();
            var refreshToken = authenticationLocalStorageService.getRefreshToken();
            var username_1 = authenticationLocalStorageService.getUsernameFromAccessToken();
            setState(__assign(__assign({}, DEFAULT_STATE), { accessToken: accessToken, refreshToken: refreshToken, username: username_1, isLoggedIn: true }));
        }
    }, []);
    // useEffect(() => {
    //   if (!state.accessToken) return;
    //   const expiration = expirationFromToken({
    //     token: state.accessToken,
    //   });
    //   const id = setTimeout(async () => {
    //     loginWithRefreshToken();
    //   }, expiration);
    //   return () => clearTimeout(id);
    // }, [state.accessToken, expirationFromToken]);
    (0, react_1.useEffect)(function () {
        var visibilityChange = function () {
            if (document.visibilityState !== 'visible')
                return;
            if (!state.accessToken)
                return;
            // const expiration = expirationFromToken({
            //   token: state.accessToken,
            // });
            // if (expiration <= 0) loginWithRefreshToken();
        };
        document.addEventListener("visibilitychange", visibilityChange);
        return function () {
            return document.removeEventListener("visibilitychange", visibilityChange);
        };
    }, [document, state.accessToken, token_1.expirationFromToken]);
    var login = function (_a) {
        var accessToken = _a.accessToken;
        return __awaiter(void 0, void 0, void 0, function () {
            var loginResults, token, refreshToken, username, _b, error_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        setState(__assign(__assign({}, state), { isLoading: true }));
                        return [4 /*yield*/, authenticationService.login({ accessToken: accessToken })];
                    case 1:
                        loginResults = _c.sent();
                        token = loginResults.token, refreshToken = loginResults.refreshToken;
                        authenticationLocalStorageService.setLoginResults({ loginResults: loginResults });
                        return [4 /*yield*/, authenticationLocalStorageService.getUsernameFromAccessToken()];
                    case 2:
                        username = _c.sent();
                        setState({
                            username: username,
                            isLoading: false,
                            isLoggedIn: true,
                            error: null,
                            accessToken: token,
                            refreshToken: refreshToken,
                        });
                        setGlobalError({ error: null });
                        return [3 /*break*/, 4];
                    case 3:
                        _b = _c.sent();
                        error_1 = _b.message;
                        setGlobalError({ error: error_1 });
                        setState(function (previousState) {
                            return __assign(__assign({}, previousState), { isLoading: false, error: error_1 });
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    var logout = (0, react_1.useCallback)(function () {
        var username = authenticationLocalStorageService.getUsername();
        authenticationLocalStorageService.clearLoginResults();
        authenticationService.logout();
        setState(__assign(__assign({}, DEFAULT_STATE), { username: username }));
    }, [state.accessToken]);
    var getUsername = function () {
        return authenticationLocalStorageService.getUsername();
    };
    var getUsernameFromAccessToken = function () {
        if (!state.accessToken)
            return "anonymous";
        var username = JSON.parse(atob(state.accessToken.split(".")[1])).username;
        return username;
    };
    var setUsername = function (_a) {
        var username = _a.username;
        authenticationLocalStorageService.setUsername({ username: username });
    };
    var removeUsername = function () {
        authenticationLocalStorageService.removeUsername();
    };
    return (React.createElement(AuthenticationContext.Provider, { value: __assign(__assign({}, state), { login: login, logout: logout, getUsername: getUsername, setUsername: setUsername, removeUsername: removeUsername, get usernameFromToken() {
                return getUsernameFromAccessToken();
            },
            get areBothTokensExpired() {
                return authenticationLocalStorageService.areBothTokensExpired();
            } }) }, children));
};
exports.AuthenticationProvider = AuthenticationProvider;
var useAuthentication = function () {
    var context = (0, react_1.useContext)(AuthenticationContext);
    /* istanbul ignore next */
    if (context === undefined) {
        throw new Error("useAuthentication must be used within a AuthenticationProvider");
    }
    return context;
};
exports.useAuthentication = useAuthentication;
