"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAuthenticationService = void 0;
var authenticationService = function (_a) {
    var cdProductAPIProvider = _a.cdProductAPIProvider;
    return ({
        login: function (_a) {
            var accessToken = _a.accessToken;
            return __awaiter(this, void 0, void 0, function () {
                var options, _b, data, error, response, status, statusText;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            options = {
                                method: "GET",
                                headers: {
                                    auth0AccessToken: accessToken,
                                },
                            };
                            return [4 /*yield*/, cdProductAPIProvider.fetchJSON("/login", options)];
                        case 1:
                            _b = (_c.sent()), data = _b.data, error = _b.error, response = _b.response;
                            status = response.status, statusText = response.statusText;
                            if (error)
                                throw new Error(error);
                            if (status > 399)
                                throw new Error(statusText);
                            return [2 /*return*/, data];
                    }
                });
            });
        },
        logout: function () {
            return __awaiter(this, void 0, void 0, function () {
                var contextInfo, returnToUrl, logoutUrl, returnToUrl, logoutUrl;
                return __generator(this, function (_a) {
                    contextInfo = Office.context.diagnostics;
                    if (contextInfo.platform === Office.PlatformType.OfficeOnline) {
                        returnToUrl = encodeURIComponent("".concat(window.location.origin, "/taskpane.html"));
                        logoutUrl = "https://".concat(process.env.AUTH0_DOMAIN, "/v2/logout?client_id=").concat(process.env.AUTH0_CLIENT_ID, "&returnTo=").concat(returnToUrl);
                        window.location.href = logoutUrl;
                    }
                    else {
                        returnToUrl = encodeURIComponent("".concat(window.location.origin, "/logout.html"));
                        logoutUrl = "https://".concat(process.env.AUTH0_DOMAIN, "/v2/logout?client_id=").concat(process.env.AUTH0_CLIENT_ID, "&returnTo=").concat(returnToUrl);
                        Office.context.ui.displayDialogAsync(logoutUrl, { height: 30, width: 20 }, function (res) {
                            var dialog = res.value;
                            dialog.addEventHandler(Office.EventType.DialogMessageReceived, function (args) {
                                var message = JSON.parse(args['message']);
                                if (message.type === 'closeDialog') {
                                    dialog.close();
                                }
                            });
                        });
                    }
                    return [2 /*return*/];
                });
            });
        },
        refreshToken: function (_a) {
            var username = _a.username, refreshToken = _a.refreshToken;
            return __awaiter(this, void 0, void 0, function () {
                var options, _b, data, error, response, status, statusText;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            options = {
                                method: "POST",
                                body: JSON.stringify({ username: username, refreshToken: refreshToken }),
                            };
                            return [4 /*yield*/, cdProductAPIProvider.fetchJSON("/refresh-token", options)];
                        case 1:
                            _b = (_c.sent()), data = _b.data, error = _b.error, response = _b.response;
                            status = response.status, statusText = response.statusText;
                            if (error)
                                throw new Error(error);
                            if (status > 399)
                                throw new Error(statusText);
                            return [2 /*return*/, data];
                    }
                });
            });
        },
    });
};
var createAuthenticationService = function (_a) {
    var cdProductAPIProvider = _a.cdProductAPIProvider;
    return authenticationService({
        cdProductAPIProvider: cdProductAPIProvider,
    });
};
exports.createAuthenticationService = createAuthenticationService;
