"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useExpander = void 0;
var react_1 = require("react");
var DEFAULT_STATE = {
    expanded: ["Metro/Market"],
};
var reducer = function (state, action) {
    var type = action.type;
    switch (type) {
        case "EXPAND": {
            return __assign(__assign({}, state), { expanded: __spreadArray(__spreadArray([], state.expanded, true), [action.id], false) });
        }
        case "COLLAPSE": {
            var id_1 = action.id;
            return __assign(__assign({}, state), { expanded: state.expanded.filter(function (currentId) { return currentId !== id_1; }) });
        }
        /* istanbul ignore next */
        default: {
            return state;
        }
    }
};
var useExpander = function () {
    var _a = (0, react_1.useReducer)(reducer, DEFAULT_STATE), state = _a[0], dispatch = _a[1];
    var expanded = state.expanded;
    var expand = (0, react_1.useCallback)(function (_a) {
        var id = _a.id;
        return dispatch({ type: "EXPAND", id: id });
    }, []);
    var collapse = (0, react_1.useCallback)(function (_a) {
        var id = _a.id;
        return dispatch({ type: "COLLAPSE", id: id });
    }, []);
    var isExpanded = function (_a) {
        var id = _a.id;
        return expanded.includes(id);
    };
    return { expand: expand, collapse: collapse, isExpanded: isExpanded, expanded: expanded };
};
exports.useExpander = useExpander;
