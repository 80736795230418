"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTopLevelState = void 0;
var errors_1 = require("../errors");
var useTopLevelState = function () {
    var errorState = (0, errors_1.useErrorState)();
    return {
        errorState: errorState,
    };
};
exports.useTopLevelState = useTopLevelState;
