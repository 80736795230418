"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyTypes = void 0;
var React = require("react");
var PickList_1 = require("../../../../components/PickList");
var property_types_1 = require("../../hooks/property-types");
var PropertyTypes = function (_a) {
    var onChange = _a.onChange, propertyTypesService = _a.propertyTypesService, hasMultiSelect = _a.hasMultiSelect, shouldBeDisabled = _a.shouldBeDisabled;
    var _b = (0, property_types_1.usePropertyTypes)({
        propertyTypesService: propertyTypesService,
    }), selected = _b.selected, propertyTypes = _b.propertyTypes, select = _b.select;
    var onPickListChange = function (nextSelected) {
        select({ selected: nextSelected });
        onChange({ selectedPropertyTypes: nextSelected });
    };
    return (React.createElement(PickList_1.PickList, { hasMultiSelect: hasMultiSelect, selectType: hasMultiSelect ? 'multi' : 'single', disabled: shouldBeDisabled, selected: selected, options: propertyTypes, onChange: onPickListChange }));
};
exports.PropertyTypes = PropertyTypes;
