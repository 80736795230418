"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Geographies = void 0;
var React = require("react");
var ChildOptionContainer_1 = require("./components/ChildOptionContainer");
var TopLayerOption_1 = require("./components/TopLayerOption");
var ChildOption_1 = require("./components/ChildOption");
var Tabs_1 = require("./components/Tabs");
var geographies_state_1 = require("./contexts/geographies-state");
var PickList_1 = require("../../../../components/PickList");
var geographies_1 = require("../../hooks/geographies");
var Geographies = function (_a) {
    var geographiesService = _a.geographiesService, onChange = _a.onChange, hasMultiSelect = _a.hasMultiSelect;
    var _b = (0, geographies_1.useGeographies)({
        geographiesService: geographiesService,
    }), zoneGeographies = _b.zoneGeographies, zone = _b.zone, selectedZoneGeographies = _b.selectedZoneGeographies, setZone = _b.setZone, setSelectedZoneGeographies = _b.setSelectedZoneGeographies, setIsPickListClicked = _b.setIsPickListClicked;
    var onPickListChange = function (selectedZoneGeographies) {
        var id = (selectedZoneGeographies[selectedZoneGeographies.length - 1] || { id: null }).id;
        if (id === "Zone/Theatre" ||
            id === "Country/Region" ||
            id === "Metro/Market")
            return;
        setSelectedZoneGeographies({ selectedZoneGeographies: selectedZoneGeographies });
        onChange({ selectedGeographies: selectedZoneGeographies });
    };
    var onPickListClicked = function () {
        setIsPickListClicked();
    };
    return (React.createElement(geographies_state_1.GeographiesStateProvider, { zone: zone, setZone: setZone },
        React.createElement(PickList_1.PickList, { hasMultiSelect: hasMultiSelect, selectType: hasMultiSelect ? 'multi' : 'single', selected: selectedZoneGeographies, options: zoneGeographies, onChange: onPickListChange, onPickListClicked: onPickListClicked, components: {
                CustomChildOptionContainer: ChildOptionContainer_1.ChildOptionContainer,
                CustomHeader: Tabs_1.Tabs,
                CustomOption: ChildOption_1.ChildOption,
                CustomOptions: [TopLayerOption_1.TopLayerOption],
            } })));
};
exports.Geographies = Geographies;
