"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedPropertyTypes = void 0;
var React = require("react");
var react_1 = require("react");
var PropertyTypes_1 = require("../../../components/PropertyTypes");
var property_types_1 = require("../../../services/property-types");
var constants_1 = require("../../../../constants");
var authentication_1 = require("../../../../authentication");
var configuration_1 = require("../../../../configuration");
var cd_product_api_1 = require("../../../../../providers/cd-product-api");
var ConnectedPropertyTypes = function (_a) {
    var onChange = _a.onChange, hasMultiSelect = _a.hasMultiSelect, shouldBeDisabled = _a.shouldBeDisabled;
    var baseServer = (0, configuration_1.useConfiguration)().baseServer;
    var accessToken = (0, authentication_1.useAuthentication)().accessToken;
    var cdProductAPIProvider = (0, react_1.useMemo)(function () {
        return (0, cd_product_api_1.createCDProductAPIProvider)({
            basePath: constants_1.PROPERTY_TYPES_BASE_PATH,
            baseServer: baseServer,
            accessToken: accessToken,
        });
    }, [accessToken]);
    var propertyTypesService = (0, react_1.useMemo)(function () {
        return (0, property_types_1.createPropertyTypesService)({
            cdProductAPIProvider: cdProductAPIProvider,
        });
    }, [cdProductAPIProvider]);
    return (React.createElement(PropertyTypes_1.PropertyTypes, { propertyTypesService: propertyTypesService, onChange: onChange, hasMultiSelect: hasMultiSelect, shouldBeDisabled: shouldBeDisabled }));
};
exports.ConnectedPropertyTypes = ConnectedPropertyTypes;
