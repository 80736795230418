"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTopLevelState = exports.TopLevelStateProvider = void 0;
var React = require("react");
var react_1 = require("react");
var top_level_state_1 = require("../../hooks/top-level-state");
var Context = (0, react_1.createContext)(null);
var TopLevelStateProvider = function (_a) {
    var children = _a.children;
    var topLevelState = (0, top_level_state_1.useTopLevelState)();
    return React.createElement(Context.Provider, { value: topLevelState }, children);
};
exports.TopLevelStateProvider = TopLevelStateProvider;
var useTopLevelState = function () {
    var context = (0, react_1.useContext)(Context);
    if (context === null)
        throw new Error("useTopLevelState requires TopLevelStateProvider parent");
    return context;
};
exports.useTopLevelState = useTopLevelState;
