"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useShowFeature = void 0;
var react_1 = require("react");
var useShowFeature = function (_a) {
    var featureFlag = _a.featureFlag, flag = _a.flag;
    var _b = (0, react_1.useState)(false), showFeature = _b[0], setShowFeature = _b[1];
    (0, react_1.useEffect)(function () {
        if (featureFlag === null)
            return;
        var feature = featureFlag.get({ flag: flag });
        setShowFeature(feature);
        featureFlag.onChange({
            flag: flag,
            changeListener: function (_a) {
                var feature = _a.feature;
                setShowFeature(feature);
            },
        });
    }, [featureFlag, flag]);
    return { showFeature: showFeature };
};
exports.useShowFeature = useShowFeature;
