"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencySettings = void 0;
var React = require("react");
var SettingDropdown_1 = require("../SettingDropdown");
var currency_1 = require("../../../types/currency");
var currencyLabelMap = {
    USD: '$ - USD - US Dollar',
    EUR: '€ - EUR - Euro',
    GBP: '£ - GBP - Pound Sterling',
    JPY: '¥ - JPY - Japanese Yen',
    AUD: '$ - AUD - Australian Dollar',
    CAN: '$ - CAN - Canadian Dollar',
    CNY: '¥ - CNY - Chinese Yuan',
    LOC: 'LOC - Local Currency',
};
var currencyOptions = currency_1.currencies.map(function (currency) { return ({
    currency: currency,
    label: currencyLabelMap[currency],
}); });
var CurrencySettings = function (_a) {
    var selectedCurrency = _a.selectedCurrency, onCurrencyChange = _a.onCurrencyChange;
    var onChange = function (option) {
        return onCurrencyChange(option.currency);
    };
    return (React.createElement(SettingDropdown_1.SettingDropdown, { label: "Display Currency as", placeholder: currencyLabelMap[selectedCurrency], valuePropertyName: "currency", options: currencyOptions, onChange: onChange }));
};
exports.CurrencySettings = CurrencySettings;
