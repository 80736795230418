"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toPropertyTypeOptions = void 0;
var order_1 = require("./order");
var toPropertySubTypeOptions = function (propertySubTypes) {
    return propertySubTypes.map(function (dataSubType) {
        var id = dataSubType.slug, label = dataSubType.label, isEnabled = dataSubType.isEnabled;
        return {
            id: id,
            label: label,
            isEnabled: isEnabled,
        };
    });
};
var toPropertyTypeOptions = function (propertyTypes) {
    var orderedPropertyTypes = (0, order_1.orderPropertyTypes)(propertyTypes);
    var propertyTypeOptions = orderedPropertyTypes.map(function (propertyType) {
        var id = propertyType.slug, label = propertyType.label, isEnabled = propertyType.isEnabled, propertySubTypes = propertyType.propertySubTypes;
        var options = toPropertySubTypeOptions(propertySubTypes);
        return {
            label: label,
            id: id,
            isEnabled: isEnabled,
            options: options,
        };
    });
    return propertyTypeOptions;
};
exports.toPropertyTypeOptions = toPropertyTypeOptions;
