"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormErrorMessage = void 0;
var styled_components_1 = require("styled-components");
exports.FormErrorMessage = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  text-align: ", ";\n  margin-top: ", ";\n  font-size: ", ";\n  line-height: 11px;\n  -webkit-font-smoothing: antialiased;\n"], ["\n  color: ", ";\n  text-align: ", ";\n  margin-top: ", ";\n  font-size: ", ";\n  line-height: 11px;\n  -webkit-font-smoothing: antialiased;\n"])), function (props) { return props.color; }, function (props) { return props.textAlign; }, function (props) { return (props.mt ? props.mt : '3px'); }, function (props) { return (props.fontSize ? props.fontSize : '11px'); });
var templateObject_1;
