"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnAuthenticatedApp = exports.history = void 0;
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var LandingPage_1 = require("./routes/LandingPage");
var history_1 = require("history");
exports.history = (0, history_1.createHashHistory)();
var UnAuthenticatedApp = function () {
    return (React.createElement(react_router_dom_1.Router, { history: exports.history },
        React.createElement(react_router_dom_1.Route, { render: function (_a) {
                var location = _a.location;
                return (React.createElement(react_router_dom_1.Switch, { location: location },
                    React.createElement(react_router_dom_1.Route, { component: function () { return React.createElement(react_router_dom_1.Redirect, { to: "/landingpage" }); }, exact: true, path: "/" }),
                    React.createElement(react_router_dom_1.Route, { component: LandingPage_1.LandingPage, path: "/landingpage" })));
            } })));
};
exports.UnAuthenticatedApp = UnAuthenticatedApp;
