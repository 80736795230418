"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchOptions = exports.SearchOptionsProvider = void 0;
var React = require("react");
var react_1 = require("react");
var reducer = function (state, action) {
    var type = action.type, payload = action.payload;
    switch (type) {
        case "SET_FREQUENCY": {
            var frequency = payload;
            return __assign(__assign({}, state), { frequency: frequency });
        }
        case "SET_ROLLING_AVERAGE": {
            var rollingAverage = payload;
            return __assign(__assign({}, state), { rollingAverage: rollingAverage });
        }
        /* istanbul ignore next */
        default:
            return state;
    }
};
var SearchOptionsContext = (0, react_1.createContext)(null);
var DEFAULT_STATE = {
    frequency: "QUARTERLY",
    rollingAverage: "NO_ROLLING",
};
var SearchOptionsProvider = function (_a) {
    var children = _a.children;
    var _b = (0, react_1.useReducer)(reducer, DEFAULT_STATE), state = _b[0], dispatch = _b[1];
    var setFrequency = (0, react_1.useCallback)(function (_a) {
        var payload = _a.frequency;
        dispatch({ type: "SET_FREQUENCY", payload: payload });
    }, []);
    var setRollingAverage = (0, react_1.useCallback)(function (_a) {
        var payload = _a.rollingAverage;
        dispatch({ type: "SET_ROLLING_AVERAGE", payload: payload });
    }, []);
    return (React.createElement(SearchOptionsContext.Provider, { value: __assign(__assign({}, state), { setFrequency: setFrequency, setRollingAverage: setRollingAverage }) }, children));
};
exports.SearchOptionsProvider = SearchOptionsProvider;
var useSearchOptions = function () {
    var context = (0, react_1.useContext)(SearchOptionsContext);
    /* istanbul ignore next */
    if (context === undefined) {
        throw new Error("useSearchOptions must be used within a SearchOptionsProvider");
    }
    return context;
};
exports.useSearchOptions = useSearchOptions;
