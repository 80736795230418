"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createConfigurationService = void 0;
var configurationService = function () { return ({
    get: function () {
        // destructuring process env is broken
        var baseServer = process.env.CD_PRODUCT_API_URL;
        var launchdarklyClientId = process.env.LAUNCHDARKLY_CLIENT_ID;
        var forgotPasswordUrl = process.env.FORGOT_PASSWORD_URL;
        var configuration = {
            baseServer: baseServer,
            launchdarklyClientId: launchdarklyClientId,
            forgotPasswordUrl: forgotPasswordUrl,
        };
        return configuration;
    },
}); };
var createConfigurationService = function () {
    return configurationService();
};
exports.createConfigurationService = createConfigurationService;
