"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Footer = void 0;
var React = require("react");
var styled_components_1 = require("styled-components");
var StyledFooter = styled_components_1.default.footer(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  grid-area: footer;\n  margin: 20px 0px;\n  text-align: left;\n  font: normal normal normal 16px Arial Narrow;\n  letter-spacing: 0px;\n  color: #242424;\n"], ["\n  grid-area: footer;\n  margin: 20px 0px;\n  text-align: left;\n  font: normal normal normal 16px Arial Narrow;\n  letter-spacing: 0px;\n  color: #242424;\n"])));
var FooterSeparator = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 2px;\n  background-color: #dedede;\n  opacity: 1;\n  margin: 20px 0px;\n"], ["\n  width: 100%;\n  height: 2px;\n  background-color: #dedede;\n  opacity: 1;\n  margin: 20px 0px;\n"])));
var FooterContainer = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: auto 1fr;\n"], ["\n  display: grid;\n  grid-template-columns: auto 1fr;\n"])));
var FooterIcon = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0px 10px;\n  width: 26px;\n  height: 31px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  margin: 0px 10px;\n  width: 26px;\n  height: 31px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var Footer = function () {
    return (React.createElement(StyledFooter, null,
        React.createElement(FooterSeparator, null),
        React.createElement(FooterContainer, null,
            React.createElement(FooterIcon, null,
                React.createElement("img", { src: "assets/questions-icon-grey.svg" })),
            React.createElement("div", { style: { fontSize: 16 } },
                "Don't have a MSCI Real Capital Analytics account?",
                React.createElement("br", null),
                "Learn how to get access ",
                React.createElement("a", { href: 'https://www.msci.com/our-solutions/real-assets/real-capital-analytics', rel: "noreferrer", target: '_blank' }, "here")))));
};
exports.Footer = Footer;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
