"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSettingsService = exports.Settings = exports.useSettings = exports.SettingsProvider = void 0;
var settings_1 = require("./contexts/settings");
Object.defineProperty(exports, "SettingsProvider", { enumerable: true, get: function () { return settings_1.SettingsProvider; } });
Object.defineProperty(exports, "useSettings", { enumerable: true, get: function () { return settings_1.useSettings; } });
var Settings_1 = require("./components/Settings");
Object.defineProperty(exports, "Settings", { enumerable: true, get: function () { return Settings_1.Settings; } });
var settings_2 = require("./services/settings");
Object.defineProperty(exports, "createSettingsService", { enumerable: true, get: function () { return settings_2.createSettingsService; } });
