"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Spinner = exports.LoaderContainer = exports.Loader = exports.LoaderRing = void 0;
var React = require("react");
var styled_components_1 = require("styled-components");
exports.LoaderRing = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  display: block;\n  position: absolute;\n  width: 90px;\n  height: 90px;\n  margin: 3px;\n  border: 3px solid #ffaf2a;\n  border-radius: 50%;\n  animation: rotate 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n  border-color: #ffaf2a transparent transparent;\n  animation-delay: ", ";\n"], ["\n  box-sizing: border-box;\n  display: block;\n  position: absolute;\n  width: 90px;\n  height: 90px;\n  margin: 3px;\n  border: 3px solid #ffaf2a;\n  border-radius: 50%;\n  animation: rotate 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n  border-color: #ffaf2a transparent transparent;\n  animation-delay: ", ";\n"])), function (_a) {
    var delay = _a.delay;
    return delay;
});
exports.Loader = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: 96px;\n  height: 96px;\n  display: inline-block;\n  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);\n"], ["\n  position: relative;\n  width: 96px;\n  height: 96px;\n  display: inline-block;\n  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);\n"])));
exports.LoaderContainer = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: 30%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 999;\n  opacity: 0.5;\n"], ["\n  position: absolute;\n  top: 30%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 999;\n  opacity: 0.5;\n"])));
var LoaderImg = styled_components_1.default.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 36px;\n  height: 36px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n"], ["\n  width: 36px;\n  height: 36px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n"])));
var Spinner = function () {
    return (React.createElement(exports.LoaderContainer, null,
        React.createElement(exports.Loader, null,
            React.createElement(exports.LoaderRing, { delay: '-.45s' }),
            React.createElement(exports.LoaderRing, { delay: '-.3s' }),
            React.createElement(exports.LoaderRing, { delay: '-.15s' }),
            React.createElement(exports.LoaderRing, null),
            React.createElement(exports.LoaderRing, null),
            React.createElement(LoaderImg, { src: "assets/iconForSpinner.svg" }))));
};
exports.Spinner = Spinner;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
