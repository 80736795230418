"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedSettingsProvider = void 0;
var React = require("react");
var react_1 = require("react");
var settings_1 = require("../../../settings");
var cd_product_api_1 = require("../../../../providers/cd-product-api");
var constants_1 = require("../../../constants");
var configuration_1 = require("../../../configuration");
var authentication_1 = require("../../../authentication");
var ConnectedSettingsProvider = function (_a) {
    var children = _a.children;
    var baseServer = (0, configuration_1.useConfiguration)().baseServer;
    var accessToken = (0, authentication_1.useAuthentication)().accessToken;
    var cdProductAPIProvider = (0, react_1.useMemo)(function () {
        return (0, cd_product_api_1.createCDProductAPIProvider)({
            basePath: constants_1.SETTINGS_BASE_PATH,
            baseServer: baseServer,
            accessToken: accessToken,
        });
    }, [accessToken]);
    var settingsService = (0, react_1.useMemo)(function () { return (0, settings_1.createSettingsService)({ cdProductAPIProvider: cdProductAPIProvider }); }, [cdProductAPIProvider]);
    // if (accessToken === null) {
    //   return null;
    // }
    return (React.createElement(settings_1.SettingsProvider, { settingsService: settingsService }, children));
};
exports.ConnectedSettingsProvider = ConnectedSettingsProvider;
