"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.orderPropertyTypes = void 0;
var order = [
    "all-property-types",
    "commercial",
    "office",
    "office-office-cbd",
    "office-office-sub",
    "industrial",
    "industrial-warehouse",
    "industrial-flex",
    "retail",
    "retail-centers",
    "retail-shops",
    "hotel",
    "hotel-limited-service",
    "hotel-full-service",
    "apartment",
    "apartment-garden",
    "apartment-mid-highrise",
    "seniors-housing-care",
    "seniors-housing-care-nursing-care",
    "seniors-housing-care-seniors-housing",
    "manufactured-housing",
    "self-storage",
    "parking-facility",
    "dev-site-land",
];
var sortPropertySubTypes = function (propertySubTypes) {
    return propertySubTypes.sort(function (propertySubType1, propertySubType2) {
        return (order.indexOf(propertySubType1.slug) -
            order.indexOf(propertySubType2.slug));
    });
};
var sortAllPropertySubTypes = function (propertyTypes) {
    return propertyTypes.map(function (propertyType) {
        var propertySubTypes = propertyType.propertySubTypes;
        return __assign(__assign({}, propertyType), { propertySubTypes: sortPropertySubTypes(propertySubTypes) });
    });
};
var orderPropertyTypes = function (propertyTypes) {
    return sortAllPropertySubTypes(propertyTypes).sort(function (propertyType1, propertyType2) {
        return (order.indexOf(propertyType1.slug) - order.indexOf(propertyType2.slug));
    });
};
exports.orderPropertyTypes = orderPropertyTypes;
