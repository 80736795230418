"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useErrorState = void 0;
var react_1 = require("react");
var top_level_state_error_1 = require("./helpers/top-level-state-error");
var INITIAL_STATE = {
    error: null,
};
var useErrorState = function () {
    var _a = (0, react_1.useState)(INITIAL_STATE), state = _a[0], setState = _a[1];
    var setError = (0, react_1.useCallback)(function (_a) {
        var error = _a.error;
        var topLevelError = (0, top_level_state_error_1.getTopLevelStateError)(error);
        setState(__assign(__assign({}, state), { error: topLevelError }));
    }, [setState]);
    return __assign(__assign({}, state), { setError: setError });
};
exports.useErrorState = useErrorState;
