"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataTypes = void 0;
var React = require("react");
var TopLayerOption_1 = require("./components/TopLayerOption");
var PickList_1 = require("../../../../components/PickList");
var data_types_1 = require("../../hooks/data-types");
var DataTypes = function (_a) {
    var onChange = _a.onChange, slugs = _a.slugs, dataTypesService = _a.dataTypesService, hasMultiSelect = _a.hasMultiSelect, shouldBeDisabled = _a.shouldBeDisabled;
    var _b = (0, data_types_1.useDataTypes)({
        dataTypesService: dataTypesService,
        slugs: slugs,
    }), selected = _b.selected, dataTypes = _b.dataTypes, select = _b.select, setIsPickListClicked = _b.setIsPickListClicked;
    var onPickListChange = function (nextSelected) {
        var lastSelected = nextSelected[nextSelected.length - 1];
        if ((lastSelected === null || lastSelected === void 0 ? void 0 : lastSelected.id) === "volume" || (lastSelected === null || lastSelected === void 0 ? void 0 : lastSelected.id) === "pricing")
            return;
        select({ selected: nextSelected });
        onChange({ selectedDataTypes: nextSelected });
    };
    var onPickListClicked = function () {
        setIsPickListClicked();
    };
    return (React.createElement("div", { onClick: onPickListClicked },
        React.createElement(PickList_1.PickList, { hasMultiSelect: hasMultiSelect, selectType: hasMultiSelect ? 'multi' : 'single', disabled: shouldBeDisabled, selected: selected, options: dataTypes, onChange: onPickListChange, components: { CustomOptions: [TopLayerOption_1.TopLayerOption] } })));
};
exports.DataTypes = DataTypes;
