"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormikInput = void 0;
var formik_1 = require("formik");
var React = require("react");
var designTokens_1 = require("../../designTokens");
var styled_1 = require("./styled");
var Messages_1 = require("../Messages");
var FormikInput = function (_a) {
    var name = _a.name, type = _a.type, label = _a.label;
    var _b = (0, formik_1.useField)({ name: name }), field = _b[0], meta = _b[1], helpers = _b[2];
    var hasError = !!meta.error;
    return (React.createElement("div", null,
        React.createElement(styled_1.Label, { htmlFor: name }, label),
        React.createElement(styled_1.DynamicTextInput, { id: name, error: meta.touched && hasError, name: field.name, onBlur: function () {
                helpers.setTouched(true);
            }, onChange: field.onChange, type: type, value: field.value }),
        meta.touched && meta.error ? (React.createElement(Messages_1.FormErrorMessage, { color: designTokens_1.designTokens.red }, meta.error)) : null));
};
exports.FormikInput = FormikInput;
