"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTopLevelStateError = void 0;
var validate_errors_1 = require("./validate-errors");
var constants_1 = require("../../../constants");
var getTopLevelStateError = function (error) {
    if ((0, validate_errors_1.isValidError)(error))
        return error;
    if (/failed to fetch/i.test(error))
        return constants_1.INTERNET_CONNECT_ERROR;
    if (error)
        return constants_1.SUPPRESSED_ERROR;
    return null;
};
exports.getTopLevelStateError = getTopLevelStateError;
