"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchPropertyTypes = exports.fetchGeographies = exports.fetchLogout = exports.fetchLogin = void 0;
var cd_product_api_1 = require("../../providers/cd-product-api");
var property_types_1 = require("../../features/property-types/services/property-types");
var fetchLogin = function (username, password) { return __awaiter(void 0, void 0, void 0, function () {
    var options, response, result, status_1, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                options = {
                    method: 'POST', // or 'PUT'
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ username: username, password: password }),
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, fetch("".concat(window.CD_PRODUCT_API_URL, "/api/authentication/v0/login"), options)];
            case 2:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 3:
                result = _a.sent();
                status_1 = response.status;
                return [2 /*return*/, __assign(__assign({}, result), { status: status_1 })];
            case 4:
                error_1 = _a.sent();
                return [2 /*return*/, __assign(__assign({}, error_1), { hasNetworkError: true, error: "Network error: ".concat(error_1.message) })];
            case 5: return [2 /*return*/];
        }
    });
}); };
exports.fetchLogin = fetchLogin;
var fetchLogout = function (accessToken) { return __awaiter(void 0, void 0, void 0, function () {
    var response, status_2, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("".concat(window.CD_PRODUCT_API_URL, "/api/authentication/v0/logout"), {
                        method: 'POST',
                        headers: {
                            accessToken: accessToken,
                        },
                    })];
            case 1:
                response = _a.sent();
                status_2 = response.status;
                return [2 /*return*/, { status: status_2 }];
            case 2:
                error_2 = _a.sent();
                return [2 /*return*/, __assign(__assign({}, error_2), { hasNetworkError: true, error: "Network error: ".concat(error_2.message) })];
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.fetchLogout = fetchLogout;
var fetchGeographies = function () { return __awaiter(void 0, void 0, void 0, function () {
    var accessToken, options, response, data, status_3, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                accessToken = localStorage.getItem('accessToken');
                options = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        accessToken: accessToken,
                    },
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, fetch("".concat(window.CD_PRODUCT_API_URL, "/api/geography/v0/geography"), options)];
            case 2:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 3:
                data = (_a.sent()).data;
                status_3 = response.status;
                return [2 /*return*/, { data: data, status: status_3 }];
            case 4:
                error_3 = _a.sent();
                return [2 /*return*/, __assign(__assign({}, error_3), { hasNetworkError: true, error: "Network error: ".concat(error_3.message) })];
            case 5: return [2 /*return*/];
        }
    });
}); };
exports.fetchGeographies = fetchGeographies;
var fetchPropertyTypes = function () { return __awaiter(void 0, void 0, void 0, function () {
    var cdProductAPIProvider, propertyTypesService, propertyTypes, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                cdProductAPIProvider = (0, cd_product_api_1.createCDProductAPIProvider)({
                    baseServer: window.CD_PRODUCT_API_URL,
                    basePath: "/api/property-type/v0",
                    accessToken: localStorage.getItem('accessToken'),
                });
                propertyTypesService = (0, property_types_1.createPropertyTypesService)({
                    cdProductAPIProvider: cdProductAPIProvider,
                });
                return [4 /*yield*/, propertyTypesService.fetch()];
            case 1:
                propertyTypes = _a.sent();
                return [2 /*return*/, propertyTypes];
            case 2:
                error_4 = _a.sent();
                return [2 /*return*/, __assign(__assign({}, error_4), { hasNetworkError: true, error: "Network error: ".concat(error_4.message) })];
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.fetchPropertyTypes = fetchPropertyTypes;
