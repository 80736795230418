"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTransactionSearchDeps = exports.TransactionSearchDepsProvider = void 0;
var React = require("react");
var react_1 = require("react");
var react_2 = require("react");
var authentication_1 = require("../../authentication/contexts/authentication");
var configuration_1 = require("../../configuration/contexts/configuration");
var cd_product_api_1 = require("../../../providers/cd-product-api");
var constants_1 = require("../../../features/constants");
var transactions_search_1 = require("../../../features/transactions-search/services/transactions-search");
var excel_1 = require("../../../providers/excel");
var spreadsheet_1 = require("../../../features/spreadsheet/services/spreadsheet");
var TransactionSearchDepsContext = (0, react_2.createContext)({});
var TransactionSearchDepsProvider = function (_a) {
    var children = _a.children;
    var baseServer = (0, configuration_1.useConfiguration)().baseServer;
    var accessToken = (0, authentication_1.useAuthentication)().accessToken;
    var excelProvider = (0, excel_1.createExcelProvider)();
    var spreadsheetService = (0, spreadsheet_1.createSpreadsheetService)({
        excelProvider: excelProvider,
    });
    var cdProductAPIProvider = (0, react_1.useMemo)(function () {
        return (0, cd_product_api_1.createCDProductAPIProvider)({
            basePath: constants_1.TRANSACTIONS_SEARCH_BASE_PATH,
            baseServer: baseServer,
            accessToken: accessToken,
        });
    }, [accessToken]);
    var transactionsSearchService = (0, react_1.useMemo)(function () {
        return (0, transactions_search_1.createTransactionsSearchService)({
            cdProductAPIProvider: cdProductAPIProvider,
        });
    }, [cdProductAPIProvider]);
    return (React.createElement(TransactionSearchDepsContext.Provider, { value: { transactionsSearchService: transactionsSearchService, spreadsheetService: spreadsheetService } }, children));
};
exports.TransactionSearchDepsProvider = TransactionSearchDepsProvider;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var useTransactionSearchDeps = function () {
    return (0, react_2.useContext)(TransactionSearchDepsContext);
};
exports.useTransactionSearchDeps = useTransactionSearchDeps;
