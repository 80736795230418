"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALL_ERRORS = exports.SUPPRESSED_ERROR = exports.EXCEL_REFRESH_ERROR = exports.INTERNET_CONNECT_ERROR = exports.CELL_EDITING_ERROR = void 0;
exports.CELL_EDITING_ERROR = "In Cell-editing mode, exit the edit mode by pressing ENTER or TAB or selecting another cell, and then try again.";
exports.INTERNET_CONNECT_ERROR = "Internet connection issues. Try again!";
exports.EXCEL_REFRESH_ERROR = "This sheet has nothing to refresh";
exports.SUPPRESSED_ERROR = "SUPPRESSED";
exports.ALL_ERRORS = [
    exports.CELL_EDITING_ERROR,
    exports.INTERNET_CONNECT_ERROR,
    exports.EXCEL_REFRESH_ERROR,
    exports.SUPPRESSED_ERROR,
];
