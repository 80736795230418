"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var styled_components_1 = require("styled-components");
var geographies_1 = require("../../../../../features/geographies");
var data_types_1 = require("../../../../../features/data-types");
var property_types_1 = require("../../../../../features/property-types");
var context_1 = require("../../../../context");
var FiltersContainer = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 5px 0px;\n"], ["\n  padding: 5px 0px;\n"])));
var Filter = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var FilterLabelContainer = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 16px;\n  font-weight: 1000;\n  padding-left: 15px;\n  margin-bottom: 5px;\n"], ["\n  font-size: 16px;\n  font-weight: 1000;\n  padding-left: 15px;\n  margin-bottom: 5px;\n"])));
var FilterSelectContainer = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 15px;\n  & > select {\n    width: 100%;\n  }\n"], ["\n  margin-bottom: 15px;\n  & > select {\n    width: 100%;\n  }\n"])));
var Filters = function () {
    var _a = (0, context_1.useFormSelection)(), handleSelectGeographies = _a.handleSelectGeographies, handleSelectDataTypes = _a.handleSelectDataTypes, handleSelectPropertyTypes = _a.handleSelectPropertyTypes, hasMultiDataTypeSelection = _a.hasMultiDataTypeSelection, hasMultiGeographiesSelection = _a.hasMultiGeographiesSelection, hasMultiPropertyTypeSelection = _a.hasMultiPropertyTypeSelection, shouldPropertyTypeBeDisabled = _a.shouldPropertyTypeBeDisabled, shouldDataTypeBeDisabled = _a.shouldDataTypeBeDisabled, slugsFromPropertyTypes = _a.slugsFromPropertyTypes;
    return (React.createElement(FiltersContainer, null,
        React.createElement(Filter, { "data-testid": "Geography-Filter" },
            React.createElement(FilterLabelContainer, null, "Geography"),
            React.createElement(FilterSelectContainer, null,
                React.createElement(geographies_1.ConnectedGeographies, { onChange: handleSelectGeographies, hasMultiSelect: hasMultiGeographiesSelection }))),
        React.createElement(Filter, null,
            React.createElement(FilterLabelContainer, null, "Property Type"),
            React.createElement(FilterSelectContainer, null,
                React.createElement(property_types_1.ConnectedPropertyTypes, { onChange: handleSelectPropertyTypes, hasMultiSelect: hasMultiPropertyTypeSelection, shouldBeDisabled: shouldPropertyTypeBeDisabled }))),
        React.createElement(Filter, null,
            React.createElement(FilterLabelContainer, null, "Data Type"),
            React.createElement(FilterSelectContainer, null,
                React.createElement(data_types_1.ConnectedDataTypes, { onChange: handleSelectDataTypes, hasMultiSelect: hasMultiDataTypeSelection, shouldBeDisabled: shouldDataTypeBeDisabled, slugs: slugsFromPropertyTypes })))));
};
exports.default = Filters;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
