"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedConfigurationProvider = void 0;
var React = require("react");
var configuration_1 = require("../../../configuration");
var ConnectedConfigurationProvider = function (_a) {
    var children = _a.children;
    var configurationService = (0, configuration_1.createConfigurationService)();
    return (React.createElement(configuration_1.ConfigurationProvider, { configurationService: configurationService }, children));
};
exports.ConnectedConfigurationProvider = ConnectedConfigurationProvider;
