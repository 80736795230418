"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAuthentication = exports.createAuthenticationLocalStorageService = exports.createAuthenticationService = exports.AuthenticationProvider = void 0;
var authentication_1 = require("./contexts/authentication");
Object.defineProperty(exports, "AuthenticationProvider", { enumerable: true, get: function () { return authentication_1.AuthenticationProvider; } });
var authentication_2 = require("./services/authentication");
Object.defineProperty(exports, "createAuthenticationService", { enumerable: true, get: function () { return authentication_2.createAuthenticationService; } });
var authentication_local_storage_1 = require("./services/authentication-local-storage");
Object.defineProperty(exports, "createAuthenticationLocalStorageService", { enumerable: true, get: function () { return authentication_local_storage_1.createAuthenticationLocalStorageService; } });
var authentication_3 = require("./contexts/authentication");
Object.defineProperty(exports, "useAuthentication", { enumerable: true, get: function () { return authentication_3.useAuthentication; } });
