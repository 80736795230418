"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGeographiesState = exports.GeographiesStateProvider = void 0;
var React = require("react");
var react_1 = require("react");
var expander_1 = require("../../hooks/expander");
var zones_1 = require("../../hooks/zones");
var GeographiesStateContext = (0, react_1.createContext)(null);
var GeographiesStateProvider = function (_a) {
    var children = _a.children, zone = _a.zone, setZone = _a.setZone;
    var expander = (0, expander_1.useExpander)();
    var zones = (0, zones_1.useZones)({ zone: zone, setZone: setZone });
    return (React.createElement(GeographiesStateContext.Provider, { value: { expander: expander, zones: zones } }, children));
};
exports.GeographiesStateProvider = GeographiesStateProvider;
var useGeographiesState = function () {
    var context = (0, react_1.useContext)(GeographiesStateContext);
    /* istanbul ignore next */
    if (context === undefined) {
        throw new Error("useGeographiesState must be used within a GeographiesStateProvider");
    }
    return context;
};
exports.useGeographiesState = useGeographiesState;
