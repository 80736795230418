"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.expirationFromToken = void 0;
var expirationFromToken = function (_a) {
    var token = _a.token;
    var exp = JSON.parse(atob(token.split(".")[1])).exp;
    var millisecondsToExpiration = exp * 1000 - Date.now();
    return millisecondsToExpiration;
};
exports.expirationFromToken = expirationFromToken;
