"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCDProductAPIProvider = void 0;
var runtime_information_1 = require("../runtime-information");
var getClientInfoQueryString = function (_a) {
    var runtimeInformationProvider = _a.runtimeInformationProvider, options = _a.options;
    return __awaiter(void 0, void 0, void 0, function () {
        var event, _b, optionsClientInfo, clientInfo, clientInfoWithOption, clientInfoString;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    event = options.event, _b = options.clientInfo, optionsClientInfo = _b === void 0 ? {} : _b;
                    return [4 /*yield*/, runtimeInformationProvider.getRuntimeInformation()];
                case 1:
                    clientInfo = _c.sent();
                    clientInfoWithOption = event
                        ? __assign(__assign(__assign({}, clientInfo), optionsClientInfo), { event: event }) : clientInfo;
                    clientInfoString = JSON.stringify(clientInfoWithOption);
                    // const clientInfoEscaped = escape(clientInfoString);
                    return [2 /*return*/, clientInfoString];
            }
        });
    });
};
var cdProductAPIProvider = function (_a) {
    var runtimeInformationProvider = _a.runtimeInformationProvider, fetch = _a.fetch, basePath = _a.basePath, accessToken = _a.accessToken;
    return ({
        fetch: function (url, options) {
            var _a;
            if (options === void 0) { options = {}; }
            return __awaiter(this, void 0, void 0, function () {
                var headerOptionAccessToken, accessTokenHeader, clientInfoQueryString, headers, fullUrl, combinedOptions;
                var _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            if (url.substr(0, 1) !== "/") {
                                throw new Error("url should start with /");
                            }
                            if (url.substr(-1) === "/" && url !== "/") {
                                throw new Error("url should not end with /");
                            }
                            headerOptionAccessToken = (_a = options === null || options === void 0 ? void 0 : options.headers) === null || _a === void 0 ? void 0 : _a["accesstoken"];
                            accessTokenHeader = {};
                            if (typeof headerOptionAccessToken === "string")
                                accessTokenHeader["accesstoken"] = headerOptionAccessToken;
                            else if (typeof accessToken === "string")
                                accessTokenHeader["accesstoken"] = accessToken;
                            return [4 /*yield*/, getClientInfoQueryString({
                                    runtimeInformationProvider: runtimeInformationProvider,
                                    options: options,
                                })];
                        case 1:
                            clientInfoQueryString = _c.sent();
                            headers = __assign(__assign(__assign({}, options.headers), accessTokenHeader), (_b = {}, _b["rca-client-info"] = clientInfoQueryString, _b));
                            fullUrl = "".concat(basePath).concat(url);
                            combinedOptions = __assign(__assign({}, options), { headers: headers });
                            return [2 /*return*/, fetch(fullUrl, combinedOptions)];
                    }
                });
            });
        },
        fetchJSON: function (url, options) {
            var _a;
            if (options === void 0) { options = {}; }
            return __awaiter(this, void 0, void 0, function () {
                var contentType, headers, response, json, hasFetchError, status, statusText, _b, message, _1, payload;
                var _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            contentType = ((_a = options.headers) === null || _a === void 0 ? void 0 : _a["Content-Type"]) || "application/json";
                            if (!/application\/json/.test(contentType)) {
                                throw new Error("Content-Type header must be 'application/json for fetchJSON");
                            }
                            headers = __assign(__assign({}, options.headers), (_c = {}, _c["Content-Type"] = contentType, _c));
                            response = null;
                            hasFetchError = false;
                            _d.label = 1;
                        case 1:
                            _d.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, this.fetch(url, __assign(__assign({}, options), { headers: headers }))];
                        case 2:
                            response = _d.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            _b = _d.sent();
                            message = _b.message;
                            json = { error: message };
                            hasFetchError = true;
                            return [3 /*break*/, 4];
                        case 4:
                            if (!hasFetchError) {
                                (status = response.status, statusText = response.statusText);
                            }
                            if (!!json) return [3 /*break*/, 8];
                            _d.label = 5;
                        case 5:
                            _d.trys.push([5, 7, , 8]);
                            return [4 /*yield*/, response.json()];
                        case 6:
                            json = _d.sent();
                            return [3 /*break*/, 8];
                        case 7:
                            _1 = _d.sent();
                            json = { error: "error parsing json, check response" };
                            return [3 /*break*/, 8];
                        case 8:
                            payload = __assign(__assign({}, json), { response: {
                                    status: status,
                                    statusText: statusText,
                                } });
                            return [2 /*return*/, payload];
                    }
                });
            });
        },
        hasAccessToken: function () {
            return !!accessToken;
        },
    });
};
var createCDProductAPIProvider = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.baseServer, baseServer = _c === void 0 ? "" : _c, _d = _b.basePath, basePath = _d === void 0 ? "" : _d, _e = _b.fetch, fetch = _e === void 0 ? window.fetch : _e, accessToken = _b.accessToken;
    if (baseServer.substr(-1) === "/") {
        throw new Error("baseServer should not end with /");
    }
    if (basePath !== "" && basePath.substr(0, 1) !== "/") {
        throw new Error("basePath should start with /");
    }
    if (basePath.substr(-1) === "/") {
        throw new Error("basePath should not end with /");
    }
    var runtimeInformationProvider = (0, runtime_information_1.createRuntimeInformationProvider)();
    var fullBasePath = "".concat(baseServer).concat(basePath);
    return cdProductAPIProvider({
        runtimeInformationProvider: runtimeInformationProvider,
        fetch: fetch,
        basePath: fullBasePath,
        accessToken: accessToken,
    });
};
exports.createCDProductAPIProvider = createCDProductAPIProvider;
