"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedAuthenticationProvider = void 0;
var React = require("react");
var constants_1 = require("../../../constants");
var configuration_1 = require("../../../configuration");
var authentication_1 = require("../../../authentication");
var cd_product_api_1 = require("../../../../providers/cd-product-api");
var ConnectedAuthenticationProvider = function (_a) {
    var children = _a.children;
    var _b = (0, configuration_1.useConfiguration)(), isLoading = _b.isLoading, baseServer = _b.baseServer;
    if (isLoading || baseServer === null)
        return null;
    var cdProductAPIProvider = (0, cd_product_api_1.createCDProductAPIProvider)({
        baseServer: baseServer,
        basePath: constants_1.AUTHENTICATION_BASE_PATH,
    });
    var authenticationService = (0, authentication_1.createAuthenticationService)({
        cdProductAPIProvider: cdProductAPIProvider,
    });
    var authenticationLocalStorageService = (0, authentication_1.createAuthenticationLocalStorageService)();
    return (React.createElement(authentication_1.AuthenticationProvider, { authenticationService: authenticationService, authenticationLocalStorageService: authenticationLocalStorageService }, children));
};
exports.ConnectedAuthenticationProvider = ConnectedAuthenticationProvider;
