"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFeatureFlag = void 0;
var react_1 = require("react");
var feature_flag_1 = require("../../services/feature-flag");
var useFeatureFlag = function (_a) {
    var launchdarkly = _a.launchdarkly;
    var featureFlag = (0, react_1.useMemo)(function () {
        return launchdarkly === null ? null : (0, feature_flag_1.createFeatureFlag)({ launchdarkly: launchdarkly });
    }, [launchdarkly, feature_flag_1.createFeatureFlag]);
    return { featureFlag: featureFlag };
};
exports.useFeatureFlag = useFeatureFlag;
